<template>
  <div id="header-menu">
    <div class="navBox">
      <div class="navtop">
        <ul class="depth1">
          <li class="gnb_nav">
            <div id="logo">
              <a>
                <img @click="goLink('main')" src="/img/logo.png" alt="CORE SPORTS" title="">
              </a>
            </div>
          </li>
        </ul>

          <li class="gnb_nav"
              v-for="(item,i) in menuItems"
              :key="i"
              @click="checkMenuTab(item.path)"
          >
            <a class="nav_1da normal" >
            <div class="nav_icon nav_icon_1"></div> {{ item.title }} </a>
          </li>



        <div class="header_right">
          <ul>
            <li @click="goLink('deposit')"><i class="mains-icon mi-deposit"></i><span>충전신청</span></li>
            <li @click="goLink('withdrawal')"><i class="mains-icon mi-withdraw2"></i><span>환전신청</span></li>
            <li @click="autoAsk"><i class="mains-icon mi-account"></i><span>계좌문의</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "userPc",
  props: {
    nowTime: {
      type: String
    },
    balance: {
      type: Object
    },
    unreadMessage: {
      type: Number
    },
    nickname: {
      type: String
    },
    gradeValue: {
      type: String
    },
    menuItems:{
      type: Array
    }
  },
  methods: {
    logout: function () {
      this.$emit('logout');
    },
    goLink: function (target) {
      this.$emit('goLink',target);
    },
    autoAsk: function () {
      this.$emit('autoAsk');
    },
    goPoint: function (){
      this.$emit('goPoint');
    },
    checkMenuTab: function (targetPath){
      this.$emit('checkMenuTab',targetPath)
    }
  }
}
</script>

<style scoped>

</style>