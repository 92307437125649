<template>
  <div
      class="px-3"
      :style="$vuetify.breakpoint.lgAndUp ? {'width': width +'px'} : {}"
  >
    <template
        v-if="$vuetify.breakpoint.lgAndUp"
    >
      <section id="ol_after" class="ol">
        <div id="ol_after_hd">
          <div class="ol_m_info">
            <div class="myinfo">
              <strong> {{ gradeValue }}</strong>&nbsp;&nbsp;
              {{ nickname }} 님
            </div>
            <div class="logout" @click="logout">
              로그아웃
            </div>
          </div>
          <div class="ol_m_detail">
            <div>보유머니</div>
            <span class="pull-right">&nbsp;원</span>
            <span id="id_my_money" class="pull-right">
          {{ balance.money | comma }} </span>
          </div>
          <div class="ol_m_detail">
            <div>보유포인트</div>
            <div @click="goLink('point')" class="exchange">전환</div>
            <span class="pull-right">&nbsp;P</span>
            <span id="id_my_point" class="pull-right">
          {{ balance.mileage | comma }} </span>
          </div>
        </div>
        <ul id="ol_after_private">
          <li>
            <div
                class="myPage"
                @click="goLink('myInfo')"
            >
              마이페이지
            </div>

          </li>
          <li>
            <v-btn
                class="btn_purple"
                block
                @click="goLink('message')"
            >
              <v-badge
                  bordered
                  color="red"
                  overlap
                  offset-x="1"
              >
                <template v-slot:badge>
                  <span>{{ unreadMessage }}</span>
                </template>
                쪽지함
              </v-badge>
            </v-btn>
            <!--        <a href="/help/memo" class="btn_purple">쪽지함</a>-->
            <!--        <span class="arm_on">0</span>-->
          </li>
        </ul>
      </section>
      <section class="ol_bottom">
        <ul id="ol_after_buttons">
          <li
              @click="goLink('customer')"
          >
            <i class="mains-icon mains-lg mi-query"></i>1:1 문의
          </li>
          <li
              @click="goLink('attendance')"
          >
            <i class="mains-icon mains-lg mi-attendance"></i>출석부
          </li>
          <li
              @click="goLink('result')"
          >
            <i class="mains-icon mains-lg mi-mypage"></i>경기결과
          </li>
          <li
              @click="goLink('myBet')">

            <i class="mains-icon mains-lg mi-history"></i>베팅내역
          </li>
        </ul>
      </section>
    </template>
    <template
        v-else
    >
      <div class="aside_mobile_user_inner" style="width:100%">
        <section id="ol_after" class="ol">
          <header id="ol_after_hd">
            <div class="ol_m_info">
              <div class="myinfo">
                <strong>{{ gradeValue }}</strong>&nbsp;&nbsp;{{ nickname }} 님
              </div>
              <div class="logout" @click="logout">
                로그아웃
              </div>
            </div>
          </header>
          <div id="ol_after_detail">
            <div class="ol_m_detail">
              <div>보유머니</div>
              <span class="pull-right">&nbsp;원</span>
              <span id="id_my_money" class="pull-right">
              {{ balance.money | comma }} </span>
            </div>
            <div class="ol_m_detail">
              <div>포인트</div>
              <div @click="goLink('point')" class="exchange">전환</div>
              <span class="pull-right">&nbsp;P</span>
              <span id="id_my_point" class="pull-right">
              {{ balance.mileage | comma }} </span>
            </div>
          </div>
          <ul id="ol_after_private">
            <li>
              <div
                  class="myPage"
                  @click="goLink('myInfo')"
              >
                마이페이지
              </div>
            </li>
            <li>
              <v-btn
                  class="btn_purple"
                  block
                  @click="goLink('message')"
              >
                <v-badge
                    bordered
                    color="red"
                    overlap
                    offset-x="1"
                >
                  <template v-slot:badge>
                    <span>{{ unreadMessage }}</span>
                  </template>
                  쪽지함
                </v-badge>
              </v-btn>
            </li>
          </ul>
        </section>
        <div class="amenu_main">
          <ul>
            <li
                @click="goLink('customer')"
            >
              <a class="nav_1da off">
                <i class="mains-icon mi-query" style="position:absolute;top:13px;left:15px;"></i>1:1 문의
              </a>
            </li>
            <li
                @click="goLink('attendance')"
            >
              <a class="nav_1da off">
                <i class="mains-icon mi-attendance" style="position:absolute;top:13px;left:15px;"></i>출석부
              </a>
            </li>
            <li
                @click="goLink('result')"
            >
              <a class="nav_1da off">
                <i class="mains-icon mi-mypage" style="position:absolute;top:13px;left:15px;"></i>경기결과
              </a>

            </li>
            <li
                @click="goLink('myBet')">
              <a class="nav_1da off">
                <i class="mains-icon mi-history" style="position:absolute;top:13px;left:15px;"></i>베팅내역
              </a>

            </li>
            <li
                @click="goLink('withdrawal')">
              <a class="nav_1da off">
                <i class="mains-icon mi-withdraw-side" style="position:absolute;top:13px;left:15px;"></i>환전신청
              </a>

            </li>
            <li
                @click="goLink('deposit')">
              <a class="nav_1da off">
                <i class="mains-icon mi-deposit-side" style="position:absolute;top:13px;left:15px;"></i>충전신청
              </a>

            </li>
            <li
                @click="goLink('customer')">
              <a class="nav_1da off">
                <i class="mains-icon mi-account-side" style="position:absolute;top:13px;left:15px;"></i>
                계좌문의
              </a>

            </li>
          </ul>
        </div>
        <div style="height:100px;"></div>
      </div>

    </template>

    <confirm-dialog
        :dialog.sync="dialog.confirm"
        :confirmData="dialog.confirmData"
        v-on:confirmLeftBtnAction="confirmLeftBtnAction"
        v-on:confirmRightBtnAction="confirmRightBtnAction">
    </confirm-dialog>
  </div>
</template>

<script>
import axios from "axios";
import {eventBus} from '@/plugins/eventBus';

export default {
  name: "userStatus",
  data: () => ({
    interval: null,
    drawer: false,
    balance: {},
    unreadMessage: 0,
    nickname: '',
    gradeValue: '',
    dialog: {
      alert: false,
      alertOption: {
        persistent: true
      },
      alertText: '',
      alertActionType: '',
      snackBar: false,
      snackBarText: '',
      confirm: false,
      confirmData: {
        title: "",
        content: "",
        leftBtn: "",
        rightBtn: "",
        data: {}
      },
    },
    connected: false,
  }),
  props: {
    width: {
      type: Number
    }
  },
  methods: {
    showAlert: function () {
      eventBus.showAlert(this.dialog.alertText, this.dialog.alertOption, this.dialog.alertActionType)
    },

    logout: function () {
      this.dialog.confirmData.leftBtn = '확인'
      this.dialog.confirmData.rightBtn = '취소'
      this.dialog.confirmData.title = '로그아웃'
      this.dialog.confirmData.content = '로그아웃 하시겠습니까?'
      this.dialog.confirm = true
    },
    confirmRightBtnAction: function () {
      this.dialog.confirmData.leftBtn = ''
      this.dialog.confirmData.rightBtn = ''
      this.dialog.confirmData.title = ''
      this.dialog.confirmData.content = ''
      this.dialog.confirm = false
    },
    confirmLeftBtnAction: async function () {
      this.$logout()
    },
    getMember: async function () {
      // if (this.$route.path !== '/login' && this.$route.path !== '/join' ) {
      //   await this.$updateMyInfo()
      // }
      this.nickname = localStorage.nickname
      if (JSON.parse(localStorage.showMemberLevel)) {
        this.gradeValue = 'Lv. ' + localStorage.gradeValue
      } else {
        this.gradeValue = ''
      }

    },
    getBalance: function () {
      axios
          .get('/app/member/balance',
              {
                params: {},
              })
          .then(response => {
            // console.log(response)
            this.balance = response.data
            eventBus.getBalance(this.balance)
          })
          .catch(async ex => {
            if (ex.response.status === 401) {
              let refreshTokenResult = await this.$refreshToken()
              if (refreshTokenResult.flag) {
                await this.getBalance()
              } else {
                if (refreshTokenResult.status === 403) {
                  this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent= true
                }
                this.dialog.alert = true;
                this.dialog.alertText = refreshTokenResult.msg

              }
            } else if (ex.response.status === 403) {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
              this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent= true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }

          })

    },
    getUnreadMessage: async function () {
      await axios
          .get('/app/messages/unread',
              {
                params: {},
              })
          .then(response => {
            // console.log(response.data)
            this.unreadMessage = response.data
          })
          .catch(async ex => {
            if (ex.response.status === 401) {
              let refreshTokenResult = await this.$refreshToken()
              if (refreshTokenResult.flag) {
                await this.getUnreadMessage()
              } else {
                if (refreshTokenResult.status === 403) {
                  this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent= true
                }
                this.dialog.alert = true;
                this.dialog.alertText = refreshTokenResult.msg

              }
            } else if (ex.response.status === 403) {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
              this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent= true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }

          })
      if (this.unreadMessage > 0 && !this.$route.path.includes('/message')) {
        this.checkUnreadMessage();
      }
    },
    checkUnreadMessage: function () {
      this.dialog.alertText = '읽지 않은 매세지가 있습니다. 확인하시겠습니까?';
      this.dialog.alert = true;
      this.dialog.alertActionType = 'goMessage'
    },


    getData: function () {
      this.getMember()
      this.getBalance()
      this.getUnreadMessage()
    },
    memberStatusCheck: function () {
      // console.log(localStorage.memberId)
      // console.log(localStorage.username)
      // console.log(localStorage.nickname)
      // console.log(localStorage.grade)
      // console.log(localStorage.gradeValue)
      // console.log(localStorage.status)
      // console.log(localStorage.statusValue)
      const status = localStorage.status;
      if (status === 'AUTHORIZED') {

      } else {
        this.dialog.alertText = '접속 불가한 계정입니다. 관리자에게 문의하시길 바랍니다.';
        this.dialog.alert = true;
        this.dialog.alertActionType = 'doNotAccess'
        // if (localStorage.status === 'UNAUTHORIZED') {
        //
        // } else if (localStorage.status === 'PAUSE') {
        //
        // } else if (localStorage.status === 'WITHDRAW') {
        //
        // }
      }
    },

    showAlarm: function (item) {
      this.dialog.alert = true;
      this.dialog.alertText = item.message;
      this.dialog.alertActionType = '';
    },

    connect() {
      this.$initSocket('USER')
      if (localStorage.memberId) {
        this.$stompUser.connect(
            {},
            frame => {
              this.connected = true;
              this.$stompUser.subscribe(`/queue/${localStorage.memberId}/alert`, async tick => {
                console.log('topbar!')
                this.showAlarm(JSON.parse(tick.body))
              });
            },
            error => {
              console.log(error);
              this.connected = false;
            }
        );
      }
    },
    disconnect() {
      this.$socketDisconnect('USER')
      this.connected = false;
    },
    tickleConnection() {
      this.connected ? this.disconnect() : this.connect();
    },
    goLink: function (target) {
      console.log(target)
      this.$goLink(target)

    },
  },
  mounted() {
    this.getData();
    this.connect();

  },
  created() {
    eventBus.$on('updateTopBarData', () => {
      this.getData()
    })

    //회원 상태 체크
    // setInterval(this.memberStatusCheck.bind(this), 3000);
    // //회원정보 불러오기
    // setInterval(this.getMember.bind(this), 5000);

  },
  watch: {
    $route(to, from) {
      // console.log(to,from)
      this.getData()
      // this.currPath = to.path
      // console.log('currpath!!@!',this.currPath)
    },
    dialog: {
      handler: function (val, oldVal) {
        if (this.dialog.alert) {
          this.showAlert()
        }
      },
      deep: true

    }

  }
}
</script>

<style scoped>

</style>
