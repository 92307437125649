<template>
  <div class="text-center">
    <v-btn
        tile
        block
        color="primary"
        height="50"
        class="mobileCartBtn"
        @click="dialogCart = true"
    >
      <v-icon left>
        mdi-cart
      </v-icon>
      베팅하기 ({{ cart.length }})
    </v-btn>
    <v-dialog
        v-model="dialogCart"
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
    >

      <v-card
          class="text-center w-100 mobileCartCard right-0 cartMobileWrap"
          tile
          outlined
          ref="cartCardMobile"

      >

        <v-card-title
            ref="cartCardTitle"
        >
          Betting Slip
          <v-spacer></v-spacer>
          <v-btn
              icon
              @click="dialogCart = false"
          >
            <v-icon>
              mdi-close
            </v-icon>

          </v-btn>

        </v-card-title>

        <perfect-scrollbar
            ref="betCartScrollbar"
            :style="scrollerStyle"
        >
          <v-list
              class="py-0 "
              dense
              outlined
              width="100%"
              ref="selectGameList"

          >
            <v-list-item
                v-for="(item,i) in cart"
                :key="i"
                class="px-0"
            >
              <v-card
                  class=""
                  :color="i % 2 === 1 ? '#4c4c4c':''"
                  width="100%"
                  outlined
                  :height="cardHeight"
              >
                <v-card-text
                    v-if="gameType === 'SPORTS' || gameType === 'LIVE'"
                    class="font-13 pa-2"
                >
                  <div>
                    <div class="d-flex justify-space-between">
                      <div class="d-flex">
                        <div class="mr-2 text-left">
                          <span>{{ item.item.teamHome }} vs {{ item.item.teamAway }}</span>
                        </div>
                        <div class="font-orange">
                          {{ item.item.matchedAt | moment('HH:mm') }}
                        </div>
                      </div>
                      <div>
                        <v-btn
                            icon
                            class="align-start justify-end"
                            plain
                            :ripple="false"
                            retain-focus-on-click
                            @click="deleteCart(item.item,item.index)"
                        >
                          <v-icon>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <p class="mb-0 text-left">{{ item.item.gameCodeText }}</p>
                  <div class="d-flex justify-space-between">
                    <p class="mb-0 font-orange">
                      {{
                        item.target === 0 ? item.item.teamHome :
                            (item.target === 2 ? item.item.teamAway : '무승부')
                      }}
                      {{ item.item.gameCode !== 'MATCH' ? '(' + item.item.handicap + ')' : '' }}

                    </p>
                    <p class="mb-0 font-orange font-weight-bold">
                      <span :ref="'odds_' + item.item.sportsGameId">
                      {{
                          item.target === 0 ? item.item.oddsHome :
                              (item.target === 2 ? item.item.oddsAway : item.item.oddsDraw)
                        }}
                      </span>
                    </p>
                  </div>
                </v-card-text>
                <v-card-text
                    v-else-if="gameType === 'virtual'"
                    class="font-13 pa-2"
                >
                  <div>
                    <div class="d-flex justify-space-between">
                      <div class="d-flex">
                        <div class="mr-2 text-left">
                          <span>{{ item.item.teamHome }} vs {{ item.item.teamAway }}</span>
                        </div>
                        <div class="font-orange">
                          {{ item.item.playedAt | moment('HH:mm') }}
                        </div>
                      </div>
                      <div>
                        <v-btn
                            icon
                            class="align-start justify-end"
                            plain
                            :ripple="false"
                            retain-focus-on-click
                            @click="deleteCart(item.item)"
                        >
                          <v-icon>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <p class="mb-0 text-left">{{ item.item.gameCodeText }}</p>
                  <div class="d-flex justify-space-between">
                    <p class="mb-0 font-orange">
                      {{
                        item.target === 0 ? item.item.teamHome :
                            (item.target === 2 ? item.item.teamAway : '무승부')
                      }}
                      {{ item.item.gameCode !== 'MATCH' ? '(' + item.item.handicap + ')' : '' }}

                    </p>
                    <p class="mb-0 font-orange font-weight-bold">
                      <span :ref="'odds_' + item.item.sportsGameId">
                      {{
                          item.target === 0 ? item.item.oddsHome :
                              (item.target === 2 ? item.item.oddsAway : item.item.oddsDraw)
                        }}
                      </span>
                      <span :ref="'oldOdds_' + item.item.sportsGameId"></span>
                    </p>
                  </div>
                </v-card-text>

                <v-card-text
                    v-else-if="gameType === 'vDogRacing'"
                    class="font-13 pa-2"
                >
                  <div>
                    <div class="d-flex justify-space-between">
                      <div class="d-flex">
                        <div class="font-orange">
                          {{ item.item.playedAt | moment('HH:mm') }}
                        </div>
                      </div>
                      <div>
                        <v-btn
                            icon
                            class="align-start justify-end"
                            plain
                            :ripple="false"
                            retain-focus-on-click
                            @click="deleteCart(item.item)"
                        >
                          <v-icon>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <p class="mb-0 text-left">{{ item.item.gameCodeText }}</p>
                  <div class="d-flex justify-space-between">
                    <p class="mb-0 font-orange">
                      {{
                        item.target === 0 ? item.item.team1 :
                            (item.target === 1 ? item.item.team2 :
                                (item.target === 2 ? item.item.team3 :
                                    item.target === 3 ? item.item.team4 :
                                        item.target === 4 ? item.item.team5 :
                                            item.target === 5 ? item.item.team6 : ''))
                      }}
                    </p>
                    <p class="mb-0 font-orange font-weight-bold">
                      <span :ref="'odds_'">
                       {{
                          item.target === 0 ? item.item.odds1 :
                              (item.target === 1 ? item.item.odds2 :
                                  (item.target === 2 ? item.item.odds3 :
                                      item.target === 3 ? item.item.odds4 :
                                          item.target === 4 ? item.item.odds5 :
                                              item.target === 5 ? item.item.odds6 : ''))
                        }}
                      </span>
                      <span :ref="'oldOdds_'"></span>
                    </p>
                  </div>
                </v-card-text>
                <v-card-text
                    v-else
                    class="font-13 pa-2"
                >
                  <div>
                    <div class="d-flex justify-space-between">
                      <div class="d-flex">
                        <div class="mr-2 text-left">
                          <span>{{ item.item.menuCodeText + ' ' + item.item.round }} 회차</span>
                        </div>
                        <div class="font-orange">
                          {{ item.item.availableBetAt | moment('HH:mm') }}
                        </div>
                      </div>
                      <div>
                        <v-btn
                            icon
                            class="align-start justify-end"
                            plain
                            :ripple="false"
                            retain-focus-on-click
                            @click="deleteCart(item.item)"
                        >
                          <v-icon>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <p class="mb-0 text-left">{{ item.item.gameCodeText }}</p>
                  <div class="d-flex justify-space-between">
                    <p class="mb-0 font-orange">
                      {{ printBetText.text }}

                    </p>
                    <p class="mb-0 font-orange font-weight-bold">
                      <span :ref="'odds_' + item.item.sportsGameId">
                      {{ printBetText.odds }}
                      </span>
                    </p>
                  </div>
                </v-card-text>
              </v-card>
            </v-list-item>
            <!--          </template>-->
            <!--        </v-virtual-scroll>-->
          </v-list>
        </perfect-scrollbar>


        <v-card
            class="w-100 position-absolute bottom-0"
            outlined
            height=""
            ref="cartCardTotal"
            tile>
          <v-card-text class="font-13"

          >
            <div>
              <div class="d-flex justify-space-between">
                <div>
                  베팅 개수
                </div>
                <div class="white--text font-weight-bold">
                  {{ betCount }}
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div>
                  배당
                </div>
                <div class="font-orange font-weight-bold">
                  {{ betOdds }}
                </div>
              </div>
              <div>
                <div class="d-flex justify-space-between align-center">
                  <div>
                    베팅금
                  </div>
                  <div class="white--text font-weight-bold">
                    <vuetify-money
                        label=""
                        placeholder=""
                        hide-details
                        dense
                        solo
                        height="30"
                        class="pa-0 ma-0 inputMoney"

                        v-model="betPrice"
                        v-bind:clearable="clearable"
                        v-bind:readonly="false"
                        v-bind:outlined="true"
                        v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                        v-bind:options="options"
                        background-color="#1E1E1E"
                    ></vuetify-money>
                  </div>
                </div>
                <div class="mt-1 mb-2 d-flex justify-end">
                  <v-chip
                      class="mr-1"
                      small
                      color="white"
                      text-color="black"
                      @click="addBetPrice(0)"
                  >
                    clear
                  </v-chip>
                  <v-chip
                      class="mr-1"
                      small
                      color="red"
                      text-color="white"
                      @click="addBetPrice(5000)"
                  >
                    0.5만
                  </v-chip>
                  <v-chip
                      class="mr-1"
                      small
                      color="red"
                      text-color="white"
                      @click="addBetPrice(30000)"
                  >
                    3만
                  </v-chip>
                  <v-chip
                      class="mr-1"
                      small
                      color="red"
                      text-color="white"
                      @click="addBetPrice(50000)"
                  >
                    5만
                  </v-chip>
                  <v-chip
                      class="mr-1"
                      small
                      color="red"
                      text-color="white"
                      @click="addBetPrice(100000)"
                  >
                    10만
                  </v-chip>
                  <v-chip
                      class="mr-1"
                      small
                      color="red"
                      text-color="white"
                      @click="addBetPrice(500000)"
                  >
                    50만
                  </v-chip>
                  <!--                  <v-chip-->
                  <!--                      class="mr-1"-->
                  <!--                      small-->
                  <!--                      color="cyan"-->
                  <!--                      text-color="white"-->
                  <!--                  >-->
                  <!--                    Half-->
                  <!--                  </v-chip>-->
                  <v-chip
                      class=""
                      small
                      color="primary"
                      text-color="white"
                      @click="addBetPrice('max')"
                  >
                    Max
                  </v-chip>
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div>
                  예상 적중금
                </div>
                <div class="font-orange font-weight-bold">
                  {{ betPrizeMoney | comma }}
                </div>
              </div>
              <div class="mt-1 mb-2 text-center">
                <div class="mb-1 d-flex">
                  <v-btn
                      tile
                      class="mr-1 flex-grow-1 flex-shrink-0 pa-0"
                      color="error"
                      @click="deleteCartAll"
                      :disabled="cart.length <= 0"
                  >
                    <v-icon left>
                      mdi-minus
                    </v-icon>
                    전체 삭제
                  </v-btn>
                  <v-btn
                      tile
                      color="green"
                      class=" flex-grow-1 flex-shrink-0 pa-0 mx-0"
                      :disabled="cart.length <= 0"
                  >
                    <v-icon left>
                      mdi-refresh
                    </v-icon>
                    배당 갱신
                  </v-btn>
                </div>
                <div>
                  <v-btn
                      tile
                      block
                      color="primary"
                      :disabled="cart.length <= 0"
                      :loading="btnLoading"
                      @click="setBetting"
                  >
                    <v-icon left>
                      mdi-check-bold
                    </v-icon>
                    베팅하기 ({{ cart.length }})
                  </v-btn>
                </div>

              </div>
            </div>

          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "cartMobile",
  data() {
    return {
      dialogCart: false,
      calcCartHeight: 0,
      cardHeight: 100,
      betPrice: 5000,
      betCount: 0,
      betOdds: 1,
      betPrizeMoney: 0,
      scrollFlag: false,
      placeHolder: '',
      valueWhenIsEmpty: '0',
      clearable: false,
      options: {
        suffix: '',
        length: 8,
        precision: 0,
      },
      cartHeight: 0,
      cartTitleHeight: 0,
      cartCardTotalHeight: 0,
      intervalFlag: null,
    }
  },


  props: {
    cart: {
      type: Array
    },
    gameType: {
      type: String
    },

    width: {
      type: Number
    },
    balance: {
      type: Object
    },
    config: {
      type: Object
    },
    btnLoading: {
      type: Boolean,
      default: false,
    }
  },

  methods: {

    refreshOdds: function () {
      this.$emit('refreshOdds')
    },
    addBetPrice: function (price) {
      console.log(this.config)
      this.betOdds = Number(Math.round(this.betOdds * 100) / 100)
      if (this.betPrice > this.balance.money) {
        this.betPrice = this.balance.money
      }
      if (price === 0) {
        this.betPrice = 0
      } else {
        if (this.cart.length === 1 && this.config.oneFolderMaxBetAmt !== null) {
          if (price === 'max') {
            if (this.balance.money > this.config.oneFolderMaxBetAmt) {
              if ((this.config.oneFolderMaxBetAmt * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.config.oneFolderMaxBetAmt
              }
            } else {
              if ((this.balance.money * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.balance.money
              }
            }
          } else {
            if (this.betPrice + price > this.config.oneFolderMaxBetAmt) {
              if ((this.config.oneFolderMaxBetAmt * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.config.oneFolderMaxBetAmt
              }
            } else {
              if (((this.betPrice + price) * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.betPrice + price
              }
            }
          }
        } else if (this.cart.length > 1) {
          if (price === 'max') {
            if (this.balance.money > this.config.maxBetAmt) {
              if ((this.config.maxBetAmt * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.config.maxBetAmt
              }
            } else {
              if ((this.balance.money * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.balance.money
              }
            }
          } else {
            if (this.betPrice + price > this.config.maxBetAmt) {
              if ((this.config.maxBetAmt * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.config.maxBetAmt
              }
            } else {
              if (((this.betPrice + price) * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.betPrice + price
              }
            }
          }
        } else {
          if (price === 'max') {
            if (this.balance.money > this.config.maxBetAmt) {
              this.betPrice = this.config.maxBetAmt
            } else {
              this.betPrice = this.balance.money
            }
          } else {
            if (this.balance.money > this.config.maxBetAmt) {
              if (this.betPrice + price > this.config.maxBetAmt) {
                this.betPrice = this.config.maxBetAmt
              } else {
                this.betPrice = this.betPrice + price
              }
            } else {
              if (this.betPrice + price > this.balance.money) {
                this.betPrice = this.balance.money
              } else {
                this.betPrice = this.betPrice + price
              }
            }
          }
        }
        this.betPrice = Math.floor(this.betPrice)
      }
    },

    deleteCart: function (data, index) {
      this.$emit('deleteCart', data, index)
    },
    deleteCartAll: function () {
      this.$emit('deleteCartAll')
    },

    onResize: async function () {
      if (this.cartHeight <= 0) {
        this.intervalFlag = setInterval(() => {
          if (this.cartHeight <= 0) {
            this.cartHeight = this.$refs.cartCardMobile.$el.clientHeight;
            this.cartTitleHeight = this.$refs.cartCardTitle.clientHeight
            this.cartCardTotalHeight = this.$refs.cartCardTotal.$el.clientHeight
            console.log(this.$refs.cartCardMobile)
            console.log(this.$refs.cartCardTitle)
            console.log("onresize", this.cartHeight, this.cartTitleHeight, this.cartCardTotalHeight)
            let selectGameListHeight = this.$refs.selectGameList.$el.clientHeight
            this.calcCartHeight = this.cartHeight - (this.cartTitleHeight + this.cartCardTotalHeight)
          } else {
            clearInterval(this.intervalFlag)
          }
        }, 500);
      }


    },
    setBetting: function () {
      let betInfo = {
        betPrice: this.betPrice,
        betCount: this.betCount,
        betOdds: this.betOdds,
        betPrizeMoney: this.betPrizeMoney
      }
      this.$emit('setBetting', betInfo)
    },
    reCalculation: function (cartList) {
      this.onResize()
      this.betCount = cartList.length;
      this.betOdds = 1;
      if (this.gameType === 'vDogRacing') {
        console.log('cart', cartList)
        for (let i = 0; i < cartList.length; i++) {
          if (cartList[i].target === 0) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds1)
          } else if (cartList[i].target === 1) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds2)
          } else if (cartList[i].target === 2) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds3)
          } else if (cartList[i].target === 3) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds4)
          } else if (cartList[i].target === 4) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds5)
          } else {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds6)
          }
        }
      } else {
        for (let i = 0; i < cartList.length; i++) {
          if (cartList[i].target === 0) {
            this.betOdds = this.betOdds * Number(cartList[i].item.oddsHome)
          } else if (cartList[i].target === 1) {
            this.betOdds = this.betOdds * Number(cartList[i].item.oddsDraw)
          } else {
            this.betOdds = this.betOdds * Number(cartList[i].item.oddsAway)
          }

        }
      }
      this.betOdds = Number(Math.round(this.betOdds * 100) / 100)
      this.betPrizeMoney = Math.round(Number(this.betPrice) * this.betOdds)
    },

  },
  computed: {
    scrollerStyle() {
      return {
        height: `${this.calcCartHeight}px`,
      }
    },
    printBetText() {
      let betText = '';
      let betOdds = '';
      if (this.cart[0].item.menuCode === 'SPEEDKENO') {
        if (this.cart[0].item.gameCode === 'ODD_EVEN') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '홀';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '짝';
            betOdds = this.cart[0].item.oddsAway
          }
        } else if (this.cart[0].item.gameCode === 'OVER_UNDER') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '오버';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '언더';
            betOdds = this.cart[0].item.oddsAway
          }
        } else if (this.cart[0].item.gameCode === 'ODD_OVER_UNDER') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '홀 + 오버';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '홀 + 언더';
            betOdds = this.cart[0].item.oddsAway
          }
        } else if (this.cart[0].item.gameCode === 'EVEN_OVER_UNDER') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '짝 + 오버';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '짝 + 언더';
            betOdds = this.cart[0].item.oddsAway
          }
        }
      } else {
        if (this.cart[0].item.gameCode === 'P_ODD_EVEN') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '파워볼 홀';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '파워볼 짝';
            betOdds = this.cart[0].item.oddsAway
          }
        } else if (this.cart[0].item.gameCode === 'P_OVER_UNDER') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '파워볼 오버';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '파워볼 언더';
            betOdds = this.cart[0].item.oddsAway
          }
        } else if (this.cart[0].item.gameCode === 'BALL_SIZE') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '일반볼 소';
            betOdds = this.cart[0].item.oddsHome
          } else if (this.cart[0].target === this.cart[0].item.gameCode + '_A') {
            betText = '일반볼 대';
            betOdds = this.cart[0].item.oddsAway
          } else {
            betText = '일반볼 중';
            betOdds = this.cart[0].item.oddsDraw
          }
        } else if (this.cart[0].item.gameCode === 'P_SUM_ODD_EVEN') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '일반볼 합 홀';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '일반볼 합 짝';
            betOdds = this.cart[0].item.oddsAway
          }
        } else if (this.cart[0].item.gameCode === 'P_SUM_OVER_UNDER') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '일반볼 합 오버';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '일반볼 합 언더';
            betOdds = this.cart[0].item.oddsAway
          }
        }
      }
      return {
        text: betText,
        odds: betOdds
      }
    }
  },
  watch: {
    balance: {
      handler: function (val, oldVal) {
        this.options.length = this.balance.money.toString().length
      },
      deep: true
    },
    config: {
      handler: function (val, oldVal) {
        this.betPrice = this.config.commonMinBetAmt
      },
      deep: true
    },
    betPrice: function () {
      if (this.betPrice > this.balance.money) {
        this.betPrice = this.balance.money
      }
      // this.betPrizeMoney = Math.round(Number(this.betPrice) * this.betOdds)
      //최대 당첨 금액
      let tempBetOdds = Number(Math.round(this.betOdds * 100) / 100)
      let tempBetPrizeMoney = Math.floor(Number(this.betPrice) * this.betOdds)
      this.betOdds = tempBetOdds
      this.betPrizeMoney = tempBetPrizeMoney
    },

    cart: function (cartList) {
      this.betCount = cartList.length;
      this.betOdds = 1;
      if (this.gameType === 'vDogRacing') {
        for (let i = 0; i < cartList.length; i++) {
          if (cartList[i].target === 0) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds1)
          } else if (cartList[i].target === 1) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds2)
          } else if (cartList[i].target === 2) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds3)
          } else if (cartList[i].target === 3) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds4)
          } else if (cartList[i].target === 4) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds5)
          } else {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds6)
          }
        }
      } else {
        for (let i = 0; i < cartList.length; i++) {

          if (cartList[i].target === 0) {
            this.betOdds = this.betOdds * Number(cartList[i].item.oddsHome)
          } else if (cartList[i].target === 1) {
            this.betOdds = this.betOdds * Number(cartList[i].item.oddsDraw)
          } else {
            this.betOdds = this.betOdds * Number(cartList[i].item.oddsAway)
          }
          this.betOdds = Number(Math.round(this.betOdds * 100) / 100)
        }
      }

      this.betPrizeMoney = Math.round(Number(this.betPrice) * this.betOdds)
    },

    dialogCart: function (val) {
      console.log("watch", val)
      if (val) {
        this.onResize()
      }
    },
    cartHeight: function (val) {
      console.log('onresize', val)
      if (val >0) {
        clearInterval(this.intervalFlag)
      }

    }

  },
  mounted() {

  },
  updated() {

  },
  created() {

  },
  beforeDestroy() {
    clearInterval(this.intervalFlag)
  }
}
</script>

<style scoped>

</style>
