import Vue from "vue";
import Router from "vue-router";
import TopBar from "@/components/_Default/TopBar.vue";
// import FooTer from "./components/_Default/Footer.vue";
import axios from "axios";

Vue.use(Router);

const updateActivity = (title) => {
  axios
      .put('/app/members/me/activity',
          {stay: title}, )
      .then(response => {

      })
      .catch(ex => {
        console.warn("ERROR!!!!! : ", ex);

      })
}

const requireAuthBeforeLogin = () => (from, to, next) => {
  let isAuthenticated = false;
  if (localStorage.accessToken !== undefined) {
    isAuthenticated = true;
  }

  if (isAuthenticated) return next('/');
  //메인페이지 완성전까지 사용
  // if (isAuthenticated) return next('/game/sports');
  next();
}
const requireAuth = () => async (from, to, next) => {
  let isAuthenticated = false;
  console.log('로컬스토리지',localStorage)
  console.log('로컬스토리지',localStorage.accessToken)
  if (localStorage.accessToken !== undefined) {
    isAuthenticated = true;
  }
  console.log(isAuthenticated)
  // if (isAuthenticated) return next('/');
  if (isAuthenticated) {
    updateActivity(from.matched[0].props.title)
    // if (from.name === 'login') {
    //   return next('/game/sports');
    // } else {
    //   return next();
    // }
    return next();
  } else {
    next('/login');
  }
}


const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "main",
      components: {
        header: TopBar,
        default: () => import("@/views/Main"),

      },
      beforeEnter: requireAuth(),
      props: {
        title: "메인페이지",
        appBarFullWidth: false
      }
    },
    {
      path: "/login",
      name: "login",
      components: {
        default: () => import("@/views/Member/Login"),
        // footer: FooTer
      },
      beforeEnter: requireAuthBeforeLogin(),
      props: {
        title: "로그인",
        appBarFullWidth: false
      }
    },
    {
      path: "/join/:code",
      name: "join",
      components: {
        default: () => import("@/views/Member/Join")
      },
      beforeEnter: requireAuthBeforeLogin()
      ,props: {
        title: "회원가입",
        appBarFullWidth: false
      },

    },
    {
      path: "/board",
      name: "board",
      components: {
        header: TopBar,
        default: () => import("@/views/Board/List")
      },
      beforeEnter: requireAuth(),
      props: {
        title: "게시판",
        appBarFullWidth: false
      }
    },

    {
      path: "/board/:id",
      name: "boardDetail",
      components: {
        header: TopBar,
        default: () => import("@/views/Board/Detail"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "게시판 상세",
        appBarFullWidth: false
      }
    },
    {
      path: "/message",
      name: "message",
      components: {
        header: TopBar,
        default: () => import("@/views/Message/List")
      },
      beforeEnter: requireAuth(),
      props: {
        title: "매세지",
        appBarFullWidth: false
      }
    },
    {
      path: "/message/:id",
      name: "messageDetail",
      components: {
        header: TopBar,
        default: () => import("@/views/Message/Detail"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "매세지 상세",
        appBarFullWidth: false
      }
    },
    {
      path: "/customer",
      name: "Customer",
      components: {
        header: TopBar,
        default: () => import("@/views/Customer/List"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "고객센터",
        appBarFullWidth: false
      }
    },
    {
      path: "/Customer/:id",
      name: "customerDetail",
      components: {
        header: TopBar,
        default: () => import("@/views/Customer/Detail"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "고객센터 상세",
        appBarFullWidth: false
      }
    },
    {
      path: "/game/sports/:id",
      name: "Sports",
      components: {
        header: TopBar,
        default: () => import("@/views/Game/Sports/List"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "스포츠",
        appBarFullWidth: true
      }
    },

    {
      path: "/game/sports/:id/:league",
      name: "SportsByLeague",
      components: {
        header: TopBar,
        default: () => import("@/views/Game/Sports/List"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "스포츠",
        appBarFullWidth: true
      }
    },

    {
      path: "/game/LIVE",
      name: "Live",
      components: {
        header: TopBar,
        default: () => import("@/views/Game/Live/List"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "라이브",
        appBarFullWidth: true
      }
    },
    {
      path: "/game/virtual",
      name: "VirtualSoccer",
      components: {
        header: TopBar,
        default: () => import("@/views/Game/Virtual/List"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "가상축구",
        appBarFullWidth: true
      }
    },

    {
      path: "/game/virtual/:menuCode",
      name: "Virtual",
      components: {
        header: TopBar,
        default: () => import("@/views/Game/Virtual/List"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "가상게임",
        appBarFullWidth: true
      }
    },

    {
      path: "/game/powerball",
      name: "Powerball5",
      components: {
        header: TopBar,
        default: () => import("@/views/Game/Powerball/List"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "파워볼5분",
        appBarFullWidth: true
      }
    },

    {
      path: "/game/powerball3",
      name: "Powerball3",
      components: {
        header: TopBar,
        default: () => import("@/views/Game/Powerball/List"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "파워볼3분",
        appBarFullWidth: true
      }
    },

    {
      path: "/game/speedkeno",
      name: "Speedkeno",
      components: {
        header: TopBar,
        default: () => import("@/views/Game/Speedkeno/List"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "스피드키노",
        appBarFullWidth: true
      }
    },

    {
      path: "/casino",
      name: "Casino",
      components: {
        header: TopBar,
        default: () => import("@/views/Game/Casino/List"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "카지노",
        appBarFullWidth: true
      }
    },


    {
      path: "/deposit",
      name: "requestDeposit",
      components: {
        header: TopBar,
        default: () => import("@/views/Deposit/Request"),
        // footer: FooTer
      },
      beforeEnter: requireAuth(),
      props: {
        title: "충전하기",
        appBarFullWidth: false
      }
    },

    {
      path: "/deposit/List",
      name: "DepositList",
      components: {
        header: TopBar,
        default: () => import("@/views/Deposit/List"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "충전내역",
        appBarFullWidth: false
      }
    },

    {
      path: "/withdrawal",
      name: "requestWithdrawal",
      components: {
        header: TopBar,
        default: () => import("@/views/Withdrawal/Request"),
      },
      beforeEnter: requireAuth()
      ,props: {
        title: "환전하기",
        appBarFullWidth: false
      }
    },

    {
      path: "/withdrawal/List",
      name: "WithdrawalList",
      components: {
        header: TopBar,
        default: () => import("@/views/Withdrawal/List"),
      },
      beforeEnter: requireAuth()
      ,props: {
        title: "환전내역",
        appBarFullWidth: false
      }
    },
    {
      path: "/point",
      name: "requestPoint",
      components: {
        header: TopBar,
        default: () => import("@/views/Point/Request"),
      },
      beforeEnter: requireAuth()
      ,props: {
        title: "포인트 전환",
        appBarFullWidth: false
      }
    },

    {
      path: "/point/List",
      name: "pointList",
      components: {
        header: TopBar,
        default: () => import("@/views/Point/List"),
      },
      beforeEnter: requireAuth()
      ,props: {
        title: "포인트 내역",
        appBarFullWidth: false
      }
    },
    {
      path: "/mybet",
      name: "mybetList",
      components: {
        header: TopBar,
        default: () => import("@/views/Mybet/List"),
      },
      beforeEnter: requireAuth()
      ,props: {
        title: "베팅내역",
        appBarFullWidth: false
      }
    },
    {
      path: "/result",
      name: "resultList",
      components: {
        header: TopBar,
        default: () => import("@/views/Result/List"),
      },
      beforeEnter: requireAuth()
      ,props: {
        title: "경기결과",
        appBarFullWidth: false
      }
    },
    {
      path: "/notice/:type",
      name: "notice",
      components: {
        header: TopBar,
        default: () => import("@/views/Notice/List")
      },
      beforeEnter: requireAuth(),
      props: {
        title: "공지사항",
        appBarFullWidth: false
      }
    },
    {
      path: "/notices/:id",
      name: "noticeDetail",
      components: {
        header: TopBar,
        default: () => import("@/views/Notice/Detail"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "공지사항"
      }
    },

    {
      path: "/myInfo",
      name: "myInfo",
      components: {
        header: TopBar,
        default: () => import("@/views/MyInfo/List"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "내정보",
        appBarFullWidth: false
      }
    },

    {
      path: "/attendance",
      name: "attendance",
      components: {
        header: TopBar,
        default: () => import("@/views/Attendance.vue"),
      },
      beforeEnter: requireAuth(),
      props: {
        title: "출석체크",
        appBarFullWidth: false
      }
    }

    // {
    //   path: "/sports",
    //   name: "sports",
    //   components: {
    //     header: TopBar,
    //     right:RightLayout,
    //     bottom:BottomLayout,
    //     default: () => import("@/views/List.vue"),
    //     // true로 설정하면 데이터를 props로도 받습니다.
    //     // props: true,
    //   },
    //   props: {
    //     title: "스포츠"
    //   }
    // },

  ],
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});
// router.beforeEach((to, from, next) => {
//   window.scrollTo(0, 0)
// })

export default router;
