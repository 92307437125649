<template>
  <div
      :style="$vuetify.breakpoint.lgAndUp ? {'width': width +'px'} : {}"
  >
    <div
        v-if="$vuetify.breakpoint.lgAndUp"
    >
      <pc
          :sports="sports"
          :popular="popular"
          :arcade="arcade"
          v-on:getGameByLeague="getGameByLeague"
          v-on:goLink="goLink"
      >
      </pc>
    </div>
    <div
        v-else
    >
      <mobile
          :sports="sports"
          :popular="popular"
          :arcade="arcade"
      ></mobile>
    </div>
    <snack-bar
        :dialog.sync=dialog.snackBar
        :text=dialog.snackBarText
    >
    </snack-bar>


  </div>

</template>

<script>
import axios from "axios";

import Pc from "@/components/Game/List/Pc";
import Mobile from "@/components/Game/List/Mobile";
import {eventBus} from '@/plugins/eventBus';

export default {
  name: "GameCategoryIndex",
  components: {
    Pc,
    Mobile
  },
  data: () => ({
    gameType: 'SPORTS',
    dialog: {
      write: false,
      writeData: {},
      alert: false,
      alertText: '',
      alertActionType: '',
      snackBar: false,
      snackBarText: '',
      confirm: false,
      confirmData: {
        title: "",
        content: "",
        leftBtn: "",
        rightBtn: "",
        data: {}
      },
    },
    sports: [],
    popular: [],
    arcade: [],
  }),
  props: {
    width: {
      type: Number
    }
  },
  methods: {
    goLink: function (target) {
      this.$goLink(target)
    },
    showAlert: function () {
      eventBus.showAlert(this.dialog.alertText, this.dialog.alertOption, this.dialog.alertActionType)
    },

    getSportsLeague: async function () {
      let isSuccess = {flag: false, code: 400, msg: ''};
      await axios
          .get(`/app/sportsGames/ver3/summary`,
              {
                params: {},
              })
          .then(response => {
            console.log('SPORTS', response)
            if (response.status === 200) {
// 사용가능
              isSuccess.flag = true;
              this.sports = response.data
            } else {
              isSuccess.flag = false;
              isSuccess.msg = "게임 불러오기 오류.";
            }
            isSuccess.code = response.status;
          })
          .catch(ex => {
            console.warn("ERROR!!!!! : ", ex);
            isSuccess.flag = false;
            isSuccess.code = ex.response.status;
            isSuccess.msg = ex.response.data.message;
          })
      if (isSuccess.flag) {

      } else {
        this.dialog.snackBar = false;
        this.dialog.snackBarText = '';
        if (isSuccess.code === 400) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
        } else if (isSuccess.code === 401) {
          let refreshTokenResult = await this.$refreshToken()
          if (refreshTokenResult.flag) {
            await this.getSportsLeague()
          } else {
            if (refreshTokenResult.status === 403) {
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            }
            this.dialog.alert = true;
            this.dialog.alertText = refreshTokenResult.msg
          }


        } else if (isSuccess.code === 403) {
          this.dialog.alert = true;
          this.dialog.alertText = isSuccess.msg
          this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent = true
        } else {
          this.dialog.alertText = '인기리그 불러오기 오류.';
          this.dialog.alert = true;
        }
      }
    },

    getPopularLeague: async function () {
      let isSuccess = {flag: false, code: 400, msg: ''};
      await axios
          .get(`/app/sportsGames/ver3/CROSS/league/summary`,
              {
                params: {},
              })
          .then(response => {
            console.log('CROSS', response)
            if (response.status === 200) {
// 사용가능
              isSuccess.flag = true;
              this.popular = response.data
            } else {
              isSuccess.flag = false;
              isSuccess.msg = "게임 불러오기 오류.";
            }
            isSuccess.code = response.status;
          })
          .catch(ex => {
            console.warn("ERROR!!!!! : ", ex);
            isSuccess.flag = false;
            isSuccess.code = ex.response.status;
            isSuccess.msg = ex.response.data.message;
          })

      if (isSuccess.flag) {

      } else {
        this.dialog.snackBar = false;
        this.dialog.snackBarText = '';
        if (isSuccess.code === 400) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
        } else if (isSuccess.code === 401) {
          let refreshTokenResult = await this.$refreshToken()
          if (refreshTokenResult.flag) {
            await this.getPopularLeague()
          } else {
            if (refreshTokenResult.status === 403) {
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            }
            this.dialog.alert = true;
            this.dialog.alertText = refreshTokenResult.msg
          }

        } else if (isSuccess.code === 403) {
          this.dialog.alert = true;
          this.dialog.alertText = isSuccess.msg
          this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent = true
        } else {
          this.dialog.alertText = '인기리그 불러오기 오류.';
          this.dialog.alert = true;
        }
      }
    },
    getArcadeLeague: async function () {
      let isSuccess = {flag: false, code: 400, msg: ''};
      await axios
          .get(`/app/liveGames/leagueSummary`,
              {})
          .then(response => {
            console.log('liveGames', response)
            if (response.status === 200) {
// 사용가능
              isSuccess.flag = true;
              this.arcade = response.data
            } else {
              isSuccess.flag = false;
              isSuccess.msg = "게임 불러오기 오류.";
            }
            isSuccess.code = response.status;
          })
          .catch(ex => {
            console.warn("ERROR!!!!! : ", ex);
            isSuccess.flag = false;
            isSuccess.code = ex.response.status;
            isSuccess.msg = ex.response.data.message;
          })

      if (isSuccess.flag) {

      } else {
        this.dialog.snackBar = false;
        this.dialog.snackBarText = '';
        if (isSuccess.code === 400) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
        } else if (isSuccess.code === 401) {
          let refreshTokenResult = await this.$refreshToken()
          if (refreshTokenResult.flag) {
            await this.getArcadeLeague()
          } else {
            if (refreshTokenResult.status === 403) {
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            }
            this.dialog.alert = true;
            this.dialog.alertText = refreshTokenResult.msg
          }

        } else if (isSuccess.code === 403) {
          this.dialog.alert = true;
          this.dialog.alertText = isSuccess.msg
          this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent = true
        } else {
          this.dialog.alertText = '인기리그 불러오기 오류.';
          this.dialog.alert = true;
        }
      }
    },

    getGameByLeague: function (item) {
      if (this.$route.name === 'sports') {
        this.$router.push({name: 'SportsByLeague', params: {id: this.$route.params.id, league: item}})
      } else {
        this.$router.push({name: 'SportsByLeague', params: {id: 'CROSS', league: item}})
      }

    },


    getData: function () {
      this.getSportsLeague()
      this.getPopularLeague()
      this.getArcadeLeague()
    }

  },
  created() {
    this.$eventBusInitAlert()
    this.$evnetBusGetDataDestroy()
    this.$eventBusGetData()

  },

  mounted() {
    this.getData()
  },
  watch: {
    dialog: {
      handler: function (val, oldVal) {
        if (this.dialog.alert) {
          this.showAlert()
        }
      },
      deep: true

    }
  }

}
</script>
<style scoped>

</style>
