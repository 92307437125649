import Vue from 'vue'
import {eventBus} from "@/plugins/eventBus";
import axios from "axios";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

let socketSports = new SockJS(process.env.VUE_APP_WS_URL);
let socketLive = new SockJS(process.env.VUE_APP_WS_URL);
let socketUser = new SockJS(process.env.VUE_APP_WS_URL);
let stompSports = Stomp.over(socketSports);
let stompLive = Stomp.over(socketLive);
let stompUser = Stomp.over(socketUser);

const methods = {
    initSocket: function (type) {
        console.log(type + " process.env.VUE_APP_WS_URL " + process.env.VUE_APP_WS_URL)
        if (type === 'SPORTS') {
            socketSports = new SockJS(process.env.VUE_APP_WS_URL);
            stompSports = Stomp.over(socketSports);
        } else if (type === 'LIVE') {
            socketLive = new SockJS(process.env.VUE_APP_WS_URL);
            stompLive = Stomp.over(socketLive);
        } else if (type === 'USER') {
            socketUser = new SockJS(process.env.VUE_APP_WS_URL);
            stompUser = Stomp.over(socketUser);
        }
    },
    socketDisconnect: function (type) {

        if ((type === 'ALL') || type === 'SPORTS') {
            console.log(type + ' socket disconnect...')
            if (stompSports) {
                stompSports.disconnect();
                socketSports.close();
            }
        }
        if ((type === 'ALL') || type === 'LIVE') {
            console.log(type + ' socket disconnect...')
            if (stompLive) {
                stompLive.disconnect();
                socketLive.close();
            }
        }
        if ((type === 'ALL') || type === 'USER') {
            console.log(type + ' socket disconnect...')
            if (stompUser) {
                stompUser.disconnect();
                socketSports.close();
            }
        }
    },
    refreshToken: async function () {
        let result = {flag: true, msg:'',status:200};
        await this.$store
            .dispatch("REFRESHTOKEN")
            .then(response => {
                result.flag = true;
                console.log("refresh !!! ok")
                console.log(response)
                // this.getData();
                // if (response.status !== 200) {
                //     this.$logout();
                // }
            })
            .catch(async ex => {
                console.log("refresh !!! catch")
                console.log(ex)
                result.flag = false
                result.msg = ex.response.data.message
                result.status = ex.response.status
                // if (ex.response.status === 403) {
                //     console.log('logout')
                //     this.$logout();
                // }
            });
        console.log('refreshToken', result)
        return result;
    },
    logout: function () {
        this.$store
            .dispatch("LOGOUT")
            .then(() => {
                console.log('logout?')
                this.$router.push({name: "login"})
            })
            .catch(ex => {
                console.log("global logout")
                console.log(ex)
            });
        this.$socketDisconnect('ALL');
    },
    updateMyInfo: async function () {
        let result = {flag: true, msg:'',status:200};
        await this.$store
            .dispatch("UPDATEMYINFO")
            .then(response => {
                result.flag = true;
                // this.getData();
                // if (response.status !== 200) {
                //     this.$logout();
                // }
            })
            .catch(async ex => {
                console.log('updateMyInfo')
                console.log(ex)
                let tempResult = {}
                result.flag = false
                if (ex.response.status === 401) {
                    tempResult = await this.$refreshToken()
                    console.log('tempResult',tempResult)
                    result.msg = tempResult.msg
                    result.status = tempResult.status
                    result.flag = tempResult.flag
                } else {
                    result.msg = ex.response.data.message
                    result.status = ex.response.status
                }
                // if (ex.response.status === 401) {
                //     this.$refreshToken()
                // } else if (ex.response.status === 403) {
                //     this.$logout();
                // }

            });
        return result;
    },
    updateSiteInfo: async function () {
        let result = {flag: true, msg:'',status:200,data:{}};
        await this.$store
            .dispatch("UPDATESITEINFO")
            .then(response => {
                console.log(response)
                result.data = response.data;
                result.flag = true
                // this.getData();
                // if (response.status !== 200) {
                //     this.$logout();
                // }
            })
            .catch(async ex => {
                console.log('UPDATESITEINFO')
                console.log(ex)
                let tempResult = {}
                result.flag = false
                if (ex.response.status === 401) {
                    tempResult = await this.$refreshToken()
                    console.log('tempResult',tempResult)
                    result.msg = tempResult.msg
                    result.status = tempResult.status
                    result.flag = tempResult.flag
                } else {
                    result.msg = ex.response.data.message
                    result.status = ex.response.status
                }

                // if (ex.response.status === 401) {
                //     this.$refreshToken()
                // } else if (ex.response.status === 403) {
                //     this.$logout();
                // }

            });
        return result;
    },
    addNumber: function (currVal, addVal) {
        //currVal = currVal.replace(/,/g,'')
        currVal = Number(currVal) + Number(addVal)
        return currVal
    },
    eventBusInitAlert: function () {
        return eventBus.$on('initAlert', () => {
            this.dialog.alert = false;
            this.dialog.alertText = ''
            this.dialog.alertActionType = ''
        })
    },
    eventBusGetData: function () {
        return eventBus.$on('getData', () => {
            this.getData();
        })
    },
    eventBusGetNowTime: function () {
        return eventBus.$on('getNowTime', (nowTime) => {
            this.setTime(nowTime)
        })
    },
    evnetBusGetNowTimeDestroy: function () {
        return eventBus.$off('getNowTime')
    },
    evnetBusGetDataDestroy: function () {
        return eventBus.$off('getData')
    },
    updateActivity: function (title) {
        axios
            .put('/app/members/me/activity',
                {stay: title},)
            .then(response => {
                console.log(response)
            })
            .catch(ex => {
                console.warn("ERROR!!!!! : ", ex);
            })
    },
    getArcadeGameCategories: async function (type) {
        let isSuccess = {flag: false, code: 400, msg: '', data: {}};
        await axios
            .get(`/app/arcade/categories`,
                {
                    params: {},
                })
            .then(response => {
                console.log("summary", response)
                if (response.status === 200) {
                    // 사용가능
                    isSuccess.flag = true;
                    isSuccess.data = response.data[type]
                } else {
                    isSuccess.flag = false;
                    isSuccess.msg = "게임 불러오기 오류.";
                }
                isSuccess.code = response.status;

            })
            .catch(ex => {
                console.warn("ERROR!!!!! : ", ex);
                isSuccess.flag = false;
                isSuccess.code = ex.response.status;
                isSuccess.msg = ex.response.data.message;
            })
        console.log(type)
        return isSuccess
    },
    checkConfig: function (config, betData) {
        console.log(config, betData)
        console.log(Number(config.maxBetOdds), Number(betData.betOdds))
        let isSuccess = {flag: false, msg: '', cause: ''};

        console.log('비교',config.maxBetAmt,betData.betPrice)

        if (config.commonMinBetAmt !== null && parseInt(config.commonMinBetAmt) > betData.betPrice) {
            isSuccess.msg = '최소 베팅 금액은 ' + Number(config.commonMinBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        } else if (config.minFolder !== null && parseInt(config.minFolder) > parseInt(betData.betCount)) {
            isSuccess.msg = '최소 폴더수는 ' + Number(config.minFolder).toLocaleString() + '개 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        } else if (config.oneFolderMaxBetAmt !== null && parseInt(betData.betCount) === 1 && parseInt(config.oneFolderMaxBetAmt) < betData.betPrice) {
            isSuccess.msg = '1폴더 최대 베팅금은 ' + Number(config.oneFolderMaxBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        } else if (config.maxBetAmt !== null && parseInt(config.maxBetAmt) < betData.betPrice) {
            isSuccess.msg = '최대 베팅 금액은 ' + Number(config.maxBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        } else if (config.maxWinAmt !== null && parseInt(config.maxWinAmt) < betData.betPrizeMoney) {
            isSuccess.msg = '최대 당첨 금액은 ' + Number(config.maxWinAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        } else if (config.minBetAmt !== null && parseInt(config.minBetAmt) > betData.betPrice) {
            isSuccess.msg = '최소 베팅 금액은 ' + Number(config.minBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        }  else if (config.maxBetOdds !== null && Number(config.maxBetOdds) < Number(betData.betOdds)) {
            isSuccess.msg = '최대 배당은 ' + Number(config.maxBetOdds).toLocaleString() + ' 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        } else if (config.use !== null && !config.use) {
            isSuccess.msg = '해당 게임은 이용이 불가합니다.<br>관리자에 문의하시길 바랍니다.'
        } else {
            isSuccess.flag = true;
        }
        // if (type === 'POWERBALL' || type === 'VSOCCER' || type === 'VBASKETBALL' || type === 'VDOGRACING') {
        //     if (parseInt(config.commonMinBetAmt) > betData.betPrice) {
        //         isSuccess.msg = '최소 베팅 금액은 ' + Number(config.commonMinBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.minFolder) > parseInt(betData.betCount)) {
        //         isSuccess.msg = '최소 폴더수는 '+ Number(config.minFolder).toLocaleString() + '개 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.maxBetAmt) < betData.betPrice) {
        //         isSuccess.msg = '최대 베팅액은 '+ Number(config.maxBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.maxWinAmt) < betData.betPrizeMoney) {
        //         isSuccess.msg = '최대 당첨액은 '+ Number(config.maxBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.minBetAmt) > betData.betPrice) {
        //         isSuccess.msg = '최소 베팅 금액은 ' + Number(config.minBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (!config.use) {
        //         isSuccess.msg = '파워볼 이용이 불가합니다.<br>관리자에 문의하시길 바랍니다.'
        //     } else {
        //         isSuccess.flag = true;
        //     }
        // } else if (type === 'CROSS') {
        //     if (parseInt(config.commonMinBetAmt) > betData.betPrice) {
        //         isSuccess.msg = '최소 베팅 금액은 ' + Number(config.commonMinBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.crossFolder) > parseInt(betData.betCount)) {
        //         isSuccess.msg = '최소 폴더수는 '+ Number(config.crossFolder).toLocaleString() + '개 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.crossMaxBetAmt) < betData.betPrice) {
        //         isSuccess.msg = '최대 베팅액은 '+ Number(config.crossMaxBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.crossMaxWinAmt) < betData.betPrizeMoney) {
        //         isSuccess.msg = '최대 당첨액은 '+ Number(config.crossMaxWinAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.crossMinBetAmt) > betData.betPrice) {
        //         isSuccess.msg = '크로스 최소 베팅 금액은 ' + Number(config.crossMinBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (!config.use) {
        //         isSuccess.msg = '크로스 이용이 불가합니다.<br>관리자에 문의하시길 바랍니다.'
        //     } else if (parseInt(betData.betCount) === 1 && parseInt(config.oneFolderMaxBetAmt) < betData.betPrice) {
        //         isSuccess.msg = '1폴더 최대 베팅금은 ' + Number(config.crossMinBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else {
        //         isSuccess.flag = true;
        //     }
        // } else if (type === 'SPECIAL') {
        //     if (parseInt(config.commonMinBetAmt) > betData.betPrice) {
        //         isSuccess.msg = '최소 베팅 금액은 ' + Number(config.commonMinBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.specialFolder) > parseInt(betData.betCount)) {
        //         isSuccess.msg = '최소 폴더수는 '+ Number(config.specialFolder).toLocaleString() + '개 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.specialMaxBetAmt) < betData.betPrice) {
        //         isSuccess.msg = '최대 베팅액은 '+ Number(config.specialMaxBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.specialMaxWinAmt) < betData.betPrizeMoney) {
        //         isSuccess.msg = '최대 당첨액은 '+ Number(config.specialMaxWinAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.specialMinBetAmt) > betData.betPrice) {
        //         isSuccess.msg = '스페셜 최소 베팅 금액은 ' + Number(config.specialMinBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (!config.use) {
        //         isSuccess.msg = '스페셜 이용이 불가합니다.<br>관리자에 문의하시길 바랍니다.'
        //     } else if (parseInt(betData.betCount) === 1 && parseInt(config.oneFolderMaxBetAmt) < betData.betPrice) {
        //         isSuccess.msg = '1폴더 최대 베팅금은 ' + Number(config.crossMinBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else {
        //         isSuccess.flag = true;
        //     }
        // } else if (type === 'LIVE') {
        //     if (parseInt(config.commonMinBetAmt) > betData.betPrice) {
        //         isSuccess.msg = '최소 베팅 금액은 ' + Number(config.commonMinBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.liveFolder) > parseInt(betData.betCount)) {
        //         isSuccess.msg = '최소 폴더수는 '+ Number(config.liveFolder).toLocaleString() + '개 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.liveMaxBetAmt) < betData.betPrice) {
        //         isSuccess.msg = '최대 베팅액은 '+ Number(config.liveMaxBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.liveMaxWinAmt) < betData.betPrizeMoney) {
        //         isSuccess.msg = '최대 당첨액은 '+ Number(config.liveMaxWinAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (parseInt(config.liveMinBetAmt) > betData.betPrice) {
        //         isSuccess.msg = '스페셜 최소 베팅 금액은 ' + Number(config.liveMinBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else if (!config.use) {
        //         isSuccess.msg = '라이브 이용이 불가합니다.<br>관리자에 문의하시길 바랍니다.'
        //     } else if (parseInt(betData.betCount) === 1 && parseInt(config.oneFolderMaxBetAmt) < betData.betPrice) {
        //         isSuccess.msg = '1폴더 최대 베팅금은 ' + Number(config.crossMinBetAmt).toLocaleString() + '원 입니다.<br> 확인 후 다시 베팅하시길 바랍니다.'
        //     } else {
        //         isSuccess.flag = true;
        //     }
        // }

        return isSuccess
    },
    getArcadeGameData: async function (menuCode, league = '') {
        let params = {}
        if (league !== '') {
            params.league = league
        }

        let isSuccess = {flag: false, code: 400, msg: '', data: {}, config: {}};
        await axios
            .get(`/app/arcade/${menuCode.toLowerCase()}`,
                {
                    params: params,
                })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    // 사용가능
                    isSuccess.flag = true;
                    for (let i = 0; i < response.data.length; i++) {
                        response.data.gameInfos[i].selected = ""
                    }
                    isSuccess.data = response.data.gameInfos
                    isSuccess.config = response.data.config
                } else {
                    isSuccess.flag = false;
                    isSuccess.msg = "게임 불러오기 오류.";
                }
                isSuccess.code = response.status;
            })
            .catch(ex => {
                console.warn("ERROR!!!!! : ", ex);
                isSuccess.flag = false;
                isSuccess.code = ex.response.status;
                isSuccess.msg = ex.response.data.message;
            })
        return isSuccess
    },

    /*
        getSiteConfig: async function () {
            let isSuccess = {flag: false, code: 400, msg: '', data: {}};
            await axios
                .get('/app/site/info',
                    {
                        params: {},
                    })
                .then(response => {
                    isSuccess.data = response.data
                    this.siteConfig = response.data
                })
                .catch(async ex => {
                    if (ex.response.status === 401) {
                        if (await this.$refreshToken()) {
                            await this.getSiteConfig()
                        }
                    } else if (ex.response.status === 403) {
                        this.$logout();
                    }
                })
                return isSuccess
        },

    */
    addCart: function (gameType, cart, gameList, item, target, gameDetailList = {}, index = undefined) {
        console.log(gameType, cart, gameList, item, target, gameDetailList, index)
        let result = {}
        let temp = {
            status: 'success',
            msg: '',
        }
        console.log('here',target)
        console.log('cart',cart)
        console.log('target',target)
        if (gameType === 'SPORTS' || gameType === 'LIVE') {
            let checkSportsMatchId = 0
            let matchSportsGameId = 0
            let checkCartSportsGameId = cart.findIndex(v => v.item.sportsGameId === item.sportsGameId)
            let checkCartMatchId = cart.findIndex(v => v.item.matchId === item.matchId)
            let checkCartEqualBet = cart.findIndex(v => v.target === target && v.item.sportsGameId === item.sportsGameId)
            if (gameType === 'SPORTS') {
                checkSportsMatchId = gameList.findIndex(v => v.matchId === item.matchId && v.isLast === true)
                matchSportsGameId = gameList[checkSportsMatchId].sportsGameId
                /*if (item.menuCode === 'HANDICAP') {
                    checkSportsMatchId = gameList.findIndex(v => v.matchId === item.matchId && v.menuCode === 'HANDICAP')
                } else if (item.menuCode === 'MATCH') {
                    checkSportsMatchId = gameList.findIndex(v => v.matchId === item.matchId && v.menuCode === 'MATCH')
                } else if (item.menuCode === 'OVER_UNDER') {
                    checkSportsMatchId = gameList.findIndex(v => v.matchId === item.matchId && v.menuCode === 'OVER_UNDER')
                } else if (item.menuCode === 'SPECIAL') {
                    if (item.gameCode === 'MATCH') {
                        checkSportsMatchId = gameList.findIndex(v => v.matchId === item.matchId && v.menuCode === 'SPECIAL' && v.gameCode === 'MATCH')
                    } else {
                        checkSportsMatchId = gameList.findIndex(v => v.matchId === item.matchId && v.menuCode === 'SPECIAL' && v.gameCode !== 'MATCH')
                    }
                }
                console.log('checkSportsMatchId',checkSportsMatchId)
                matchSportsGameId = gameList[checkSportsMatchId].sportsGameId
                console.log(checkSportsMatchId, matchSportsGameId)*/

            } else {
                checkSportsMatchId = gameList.findIndex(v => v.matchId === item.matchId)
                matchSportsGameId = gameList[checkSportsMatchId].sportsGameId
            }

            if (cart.length >= 10) {
                temp.status = 'fail'
                temp.msg = '최대 10폴더까지 베팅가능합니다.';
            }

            // 베팅제약
            console.log(1,checkCartMatchId)
            console.log(2,checkCartEqualBet)
            console.log(3,checkCartSportsGameId)
            if (checkCartMatchId !== -1 && checkCartEqualBet === -1 && checkCartSportsGameId === -1) {
                console.log('제약시작',item.sports)
                for (let i = 0; i < cart.length; i++) {
                    if (item.matchId === cart[i].item.matchId) {
                        if ((item.gameCode === 'MATCH' && cart[i].item.gameCode === 'HANDICAP') || (item.gameCode === 'HANDICAP' && cart[i].item.gameCode === 'MATCH')) {
                            temp.status = 'fail'
                            temp.msg = '동일 경기 승무패와 핸디캡은 조합할 수 없습니다.';
                            // this.dialog.alertText = '동일 경기 승무패와 핸디캡은 조합할 수 없습니다.';
                            // this.dialog.alert = true;
                            // return;
                        }

                        if (item.sports === 'football' || item.sports === '축구') {
                            if ((item.gameCode === 'HANDICAP' && cart[i].item.gameCode === 'OVER_UNDER') || (item.gameCode === 'OVER_UNDER' && cart[i].item.gameCode === 'HANDICAP')) {
                                temp.status = 'fail'
                                temp.msg = '축구 동일 경기 핸디캡과 오버언더 게임은 조합할 수 없습니다.';
                                // this.dialog.alertText = '동일 경기 승무패와 핸디캡은 조합할 수 없습니다.';
                                // this.dialog.alert = true;
                                // return;
                            }

                            if ((target === 1 && cart[i].item.gameCode === 'OVER_UNDER') || (item.gameCode === 'OVER_UNDER' && cart[i].target === 1)) {
                                temp.status = 'fail'
                                temp.msg = '축구 동일 경기 무승부와 오버언더 게임은 조합할 수 없습니다.';
                                // this.dialog.alertText = '동일 경기 승무패와 핸디캡은 조합할 수 없습니다.';
                                // this.dialog.alert = true;
                                // return;
                            }
                        }

                        if ( (item.sports === 'volleyball' || item.sports === '배구') && this.$route.params.id === 'SPECIAL' ) {
                            if ((item.gameCode === 'HANDICAP' && cart[i].item.gameCode === 'OVER_UNDER') || (item.gameCode === 'OVER_UNDER' && cart[i].item.gameCode === 'HANDICAP')) {
                                temp.status = 'fail'
                                temp.msg = '배구 스페셜 동일 경기 핸디캡과 오버언더 게임은 조합할 수 없습니다.';
                            }

                        }

                        if (item.gameCode === 'MATCH' && cart[i].item.gameCode === 'MATCH') {
                            temp.status = 'fail'
                            temp.msg = '동일 경기 승무패 게임은 조합할 수 없습니다.';
                            // this.dialog.alertText = '동일 경기 승무패 게임은 조합할 수 없습니다.';
                            // this.dialog.alert = true;
                            // return;
                        }
                        if (item.gameCode === 'HANDICAP' && cart[i].item.gameCode === 'HANDICAP') {
                            temp.status = 'fail'
                            temp.msg = '동일 경기 핸디캡 게임은 조합할 수 없습니다.';
                            // this.dialog.alertText = '동일 경기 핸디캡 게임은 조합할 수 없습니다.';
                            // this.dialog.alert = true;
                            // return;
                        }
                        if (item.gameCode === 'OVER_UNDER' && cart[i].item.gameCode === 'OVER_UNDER') {
                            temp.status = 'fail'
                            temp.msg = '동일 경기 오버언더 게임은 조합할 수 없습니다.';
                            // this.dialog.alertText = '동일 경기 오버언더 게임은 조합할 수 없습니다.';
                            // this.dialog.alert = true;
                            // return;
                        }
                    }
                }
            }
            // 색상 처리
            //메인 베팅일 경우
            if (temp.status === 'success') {
                if (index !== undefined) {
                    if (gameDetailList[matchSportsGameId]) {
                        //동일 그룹 상세베팅에 선택된것이 있는경우
                        /*if (gameDetailList[matchSportsGameId].findIndex(v => v.selected !== "") !== -1) {
                          for (let i = 0; i < gameDetailList[matchSportsGameId].length; i++) {
                            gameDetailList[matchSportsGameId][i].selected = ""
                          }
                        }*/
                    }
                    // gameList[gameList.findIndex(v => v.sportsGameId === item.sportsGameId)].selected = target
                    gameList[index].selected = target
                    // this.$set(gameList[index], 'selected', target);
                } else {
                    console.log('special')
                    //스페셜 베팅일 경우
                    /*for (let i = 0; i < gameDetailList[matchSportsGameId].length; i++) {
                      gameDetailList[matchSportsGameId][i].selected = ""
                    }*/
                    console.log(item.sportsGameId)
                    gameDetailList[matchSportsGameId][gameDetailList[matchSportsGameId].findIndex(v => v.sportsGameId === item.sportsGameId)].selected = target
                    // gameDetailList[matchSportsGameId][gameDetailList[matchSportsGameId].findIndex(v => v.sportsGameId !== item.sportsGameId)].selected = ""
                    //gameList[checkSportsMatchId].selected = ""
                }
                //cart
                //동일경기에 대한 베팅이 없을 경우
                if (checkCartSportsGameId === -1) {
                    //이미 카트에 들어가 있지 않으면
                    cart.push({item: item, target: target, index: index})
                } else {
                    //동일 베팅 카트에 들어가있는 경우
                    //타켓이 같지 않으면
                    if (checkCartEqualBet === -1) {
                        // cart[cart.findIndex(v => v.item.sportsGameId === item.sportsGameId)] = {item:item,target:target}
                        this.$set(cart, checkCartSportsGameId, {
                            item: item,
                            target: target
                        });
                    } else {
                        //타겟이 같으면 베팅 취소
                        // this.deleteCart(item,index)
                        temp.status = 'delete'
                    }
                }
            }
            result = {
                cart: cart,
                gameList: gameList,
                item: item,
                target: target,
                gameDetailList: gameDetailList,
                index: index,
                // success , fail , delete
                status: temp.status,
                msg: temp.msg
            };
        } else {
            //ETC 버전
            let checkCartGameCode = cart.findIndex(v => v.item.gameCode === item.gameCode)
            let checkCartEqualBet = cart.findIndex(v => v.target === target && v.item.gameCode === item.gameCode)

            if (gameList[index].selected === target) {
                gameList[index].selected = ''
            } else {
                for (let i = 0; i < gameList.length; i++) {
                    gameList[i].selected = ''
                }
                gameList[index].selected = target
            }
            if (checkCartGameCode === -1) {
                //이미 카트에 들어가 있지 않으면
                cart = []
                cart.push({item: item, target: target})
            } else {
                //동일 베팅 카트에 들어가있는 경우
                //타켓이 같지 않으면
                if (checkCartEqualBet === -1) {
                    // cart[cart.findIndex(v => v.item.sportsGameId === item.sportsGameId)] = {item:item,target:target}
                    cart = []
                    cart.push({item: item, target: target})
                    // this.$set(cart, checkCartGameCode, {
                    //     item: item,
                    //     target: target
                    // });
                } else {
                    //타겟이 같으면 베팅 취소
                    // this.deleteCart(item,index)
                    temp.status = 'delete'
                }
            }



            result = {
                cart: cart,
                gameList: gameList,
                item: item,
                target: target,
                gameDetailList: gameDetailList,
                index: index,
                // success , fail , delete
                status: temp.status,
                msg: temp.msg
            };
        }
        return result
    },
    deleteCart: function (item, index, cart, gameList = [], gameDetailList = [], gameType = 'SPORTS') {
        let checkSportsMatchId = 0
        let matchSportsGameId = 0
        if (gameType === 'SPORTS') {
            checkSportsMatchId = gameList.findIndex(v => v.matchId === item.matchId && v.isLast === true)
            matchSportsGameId = gameList[checkSportsMatchId].sportsGameId
            /*if (item.menuCode === 'HANDICAP') {
                checkSportsMatchId = gameList.findIndex(v => v.matchId === item.matchId && v.menuCode === 'HANDICAP')
            } else if (item.menuCode === 'MATCH') {
                checkSportsMatchId = gameList.findIndex(v => v.matchId === item.matchId && v.menuCode === 'MATCH')
            }*/

        } else {
            matchSportsGameId = gameList[gameList.findIndex(v => v.matchId === item.matchId)].sportsGameId
        }

        console.log(matchSportsGameId)
        if (cart.findIndex(v => v.item.sportsGameId === item.sportsGameId) === -1) {

        } else {
            if (index !== undefined) {
                gameList[gameList.findIndex(v => v.sportsGameId === item.sportsGameId)].selected = ""
            } else {
                if (gameDetailList[matchSportsGameId].length > 0) {
                    gameDetailList[matchSportsGameId][gameDetailList[matchSportsGameId].findIndex(v => v.sportsGameId === item.sportsGameId)].selected = ""
                }
                console.log(gameDetailList[matchSportsGameId])

            }
            this.$delete(cart, cart.findIndex(v => v.item.sportsGameId === item.sportsGameId))
        }
        return {
            cart: cart,
            gameList: gameList,
            gameDetailList: gameDetailList
        }
    },


    goLink: function (target) {

        if (target === 'login') {
            if (this.$route.path !== '/login') {
                this.$router.push({name: 'login'})
            }
        } else if (target === 'myBet') {
            if (this.$route.path !== '/mybet') {
                this.$router.push({name: 'mybetList'})
            } else {
                eventBus.getData()
            }
        }
        if (target === 'result') {
            if (this.$route.path !== '/result') {
                this.$router.push({name: 'resultList'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'customer') {
            if (this.$route.path !== '/customer') {
                this.$router.push({name: 'Customer'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'deposit') {
            if (this.$route.path !== '/deposit') {
                this.$router.push({name: 'requestDeposit'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'withdrawal') {
            if (this.$route.path !== '/withdrawal') {
                this.$router.push({name: 'requestWithdrawal'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'point') {
            if (this.$route.path !== '/point') {
                this.$router.push({name: 'requestPoint'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'main') {
            if (this.$route.path !== '/') {
                this.$router.push({name: 'main'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'myInfo') {
            if (this.$route.path !== '/myInfo') {
                this.$router.push({name: 'myInfo'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'message') {
            if (this.$route.path !== '/message') {
                this.$router.push({name: 'message'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'notice') {
            if (this.$route.path !== '/notice') {
                this.$router.push({name: 'notice'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'attendance') {
            if (this.$route.path !== '/attendance') {
                this.$router.push({name: 'attendance'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'CROSS') {
            if (this.$route.path !== '/attendance') {
                this.$router.push({name: 'attendance'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'SPECIAL') {
            if (this.$route.path !== '/attendance') {
                this.$router.push({name: 'attendance'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'LIVE') {
            if (this.$route.path !== '/attendance') {
                this.$router.push({name: 'attendance'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'POWERBALL') {
            if (this.$route.path !== '/game/powerball') {
                this.$router.push({name: 'Powerball5'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'VSOCCER') {
            if (this.$route.path !== '/game/virtual') {
                this.$router.push({name: 'VirtualSoccer'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'SPEEDKENO') {
            if (this.$route.path !== '/game/speedkeno') {
                this.$router.push({name: 'Speedkeno'})
            } else {
                eventBus.getData()
            }
        } else if (target === 'POWERBALL3M') {
            if (this.$route.path !== '/game/powerball3') {
                this.$router.push({name: 'Powerball3'})
            } else {
                eventBus.getData()
            }
        }
    },
    checkUsePageFlag: function (page, flag) {
        let dialog = {alert: false, alertActionType: '', alertText: '', persistent: false}
        if (!flag) {
            dialog.alert = true
            dialog.alertActionType = 'goBack'
            dialog.persistent = true

            if (page === 'charge') {
                dialog.alertText = '현재 충전이 불가합니다.'
            } else if (page === 'exChange') {
                dialog.alertText = '현재 환전이 불가합니다.'
            } else if (page === 'sports') {
                dialog.alertText = '현재 스포츠 게임을 이용할 수 없습니다.'
            } else if (page === 'casino') {
                dialog.alertText = '현재 카지노 게임을 이용할 수 없습니다.'
            } else if (page === 'powerball') {
                dialog.alertText = '현재 EOS파워볼 5분 게임을 이용할 수 없습니다.'
            } else if (page === 'powerball3m') {
                dialog.alertText = '현재 EOS파워볼 3분 게임을 이용할 수 없습니다.'
            } else if (page === 'inplay') {
                dialog.alertText = '현재 인플레이 게임을 이용할 수 없습니다.'
            } else if (page === 'tokenGame') {
                dialog.alertText = '현재 토큰 게임을 이용할 수 없습니다.'
            } else if (page === 'vBasketball') {
                dialog.alertText = '현재 가상 농구 게임을 이용할 수 없습니다.'
            } else if (page === 'vDogracing') {
                dialog.alertText = '현재 가상 개경주 게임을 이용할 수 없습니다.'
            } else if (page === 'vSoccer') {
                dialog.alertText = '현재 가상 축구 게임을 이용할 수 없습니다.'
            } else if (page === 'live') {
                dialog.alertText = '현재 라이브 게임을 이용할 수 없습니다.'
            } else if (page === 'attendance') {
                dialog.alertText = '현재 출석체크를 이용할 수 없습니다.'
            } else if (page === 'speedkeno') {
                dialog.alertText = '현재 스피드키노 게임을 이용할 수 없습니다.'
            }
        }
        return dialog
    },
    alertAction: function (actionType) {
        console.log(actionType)
        this.dialog.alert = !this.dialog.alert
        this.alertText = ''
        this.alertOption= {
            persistent : false
        }
        this.alertActionType = ''
        if (actionType === 'goMessage') {
            this.$goLink('message');
        } else if (actionType === 'goCustomer') {
            this.$goLink('customer');
        } else if (actionType === 'logout' || actionType === 'doNotAccess'){
            this.$logout()
        } else if (actionType === 'goBack') {
            this.$router.go(-1)
        } else if (actionType === 'joinComplete' || actionType === 'failCheckRecommender') {
            this.$goLink('login');
        }
    }
}


export default {
    install(Vue) {
        //변수
        Vue.prototype.$stompSports = stompSports
        Vue.prototype.$stompLive = stompLive
        Vue.prototype.$stompUser = stompUser


        //함수
        Vue.prototype.$refreshToken = methods.refreshToken
        Vue.prototype.$logout = methods.logout
        Vue.prototype.$addNumber = methods.addNumber
        Vue.prototype.$updateMyInfo = methods.updateMyInfo
        Vue.prototype.$eventBusGetData = methods.eventBusGetData
        Vue.prototype.$evnetBusGetDataDestroy = methods.evnetBusGetDataDestroy
        Vue.prototype.$eventBusGetNowTime = methods.eventBusGetNowTime
        Vue.prototype.$evnetBusGetNowTimeDestroy = methods.evnetBusGetNowTimeDestroy
        Vue.prototype.$updateActivity = methods.updateActivity
        Vue.prototype.$addCart = methods.addCart
        Vue.prototype.$getArcadeGameCategories = methods.getArcadeGameCategories
        Vue.prototype.$getArcadeGameData = methods.getArcadeGameData
        Vue.prototype.$checkConfig = methods.checkConfig
        Vue.prototype.$socketDisconnect = methods.socketDisconnect
        Vue.prototype.$initSocket = methods.initSocket
        Vue.prototype.$deleteCart = methods.deleteCart
        Vue.prototype.$goLink = methods.goLink
        Vue.prototype.$updateSiteInfo = methods.updateSiteInfo
        Vue.prototype.$checkUsePageFlag = methods.checkUsePageFlag
        Vue.prototype.$alertAction = methods.alertAction
        Vue.prototype.$eventBusInitAlert = methods.eventBusInitAlert


    }
}



