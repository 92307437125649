<template>
  <v-app
      id="app"
      :style="this.$route.path === '/login' || this.$route.path === '/join' ? 'background:unset':''"
  >
    <div v-if="dialog.underMaintenance">
      <under-maintenance-dialog
          :dialog.sync=dialog.underMaintenance
          :text=dialog.underMaintenanceText
      ></under-maintenance-dialog>
    </div>
    <div v-else>
      <div v-if="result">
        <router-view name="header"></router-view>
        <v-main>
          <div v-if="$vuetify.breakpoint.lgAndUp">

            <!--          반응형-->
            <v-row
                class="w-100"
                no-gutters
            >
              <v-col
                  cols="2"
                  class="position-relative pa-0"
                  v-if="(this.$route.path.indexOf('/login') < 0) && (this.$route.path.indexOf('/join') < 0)"
              >
                <div class="layouts">
                  <div class="conri"
                       ref="leftLayout"
                       v-resize="onReSize"
                  >
                    <game-list
                        :width="leftLayoutWidth"
                    />

                  </div>
                </div>

              </v-col>
              <v-col
                  :cols="(this.$route.path.indexOf('/login') < 0) && (this.$route.path.indexOf('/join') < 0) ? 8 : 12"
                  class="position-relative pa-0"
              >
                <div class="layouts">
                  <router-view></router-view>
                </div>
              </v-col>
              <v-col
                  cols="2"
                  class="position-relative pa-0"
                  v-if="(this.$route.path.indexOf('/login') < 0) && (this.$route.path.indexOf('/join') < 0)"
              >
                <div class="layouts mt-2">
                  <div class="conri"
                       ref="rightLayout"
                       v-resize="onReSize"
                  >
                    <status
                        :width="rightLayoutWidth"
                    />
                    <bet-cart
                        :width="rightLayoutWidth"
                        :balance="balance"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>

          <div
              v-else
              class="pt-4"
          >
            <router-view name="header"></router-view>

            <!--          반응형-->
            <v-row
                class="w-100"
                no-gutters
            >
              <v-col
                  cols="12"

              >
                <router-view></router-view>
                <div>

                  <bet-cart
                      v-if="(this.$route.path.indexOf('/login') < 0) && (this.$route.path.indexOf('/join') < 0)"
                      :width="rightLayoutWidth"
                      :balance="balance"
                  />
                </div>

              </v-col>

            </v-row>
          </div>
        </v-main>
      </div>

    </div>
    <alert-dialog
        :dialog.sync=dialog.alert
        :text=dialog.alertText
        :option="dialog.alertOption"
        v-on:alertAction="alertAction"
    ></alert-dialog>

    <!--    <router-view name="footer"></router-view>-->
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';

import Status from "@/components/_Default/User/Status";
import BetCart from "@/views/Game/Cart/Index";
import GameList from "@/views/Game/List/Index";

import BetCartMobile from "@/components/Game/Cart/Mobile";
import GameListMobile from "@/components/Game/List/Sports/Mobile";
import {eventBus} from "@/plugins/eventBus";
import '@/styles/core.css'
// import '@/styles/default.css'
export default {
  name: 'App',
  components: {
    BetCart,
    GameList,
    Status
  },
  data: () => ({
    rightLayoutWidth: 0,
    leftLayoutWidth: 0,
    balance: {},
    result: false,
    dialog: {
      write: false,
      writeData: {},
      alert: false,
      alertText: '',
      alertOption: {
        persistent: false
      },
      alertActionType: '',
      snackBar: false,
      snackBarText: '',
      confirm: false,
      confirmData: {
        title: "",
        content: "",
        leftBtn: "",
        rightBtn: "",
        data: {}
      },
      underMaintenance: false,
      underMaintenanceText: '',

    },
  }),
  methods: {
    showAlert: function (text,option,action) {
      console.log(text,option,action)
      if (text !== undefined) {
        this.dialog.alert = true;
        this.dialog.alertText = text
        this.dialog.alertOption = option
        this.dialog.alertActionType = action
      }

    },
    alertAction : function () {
      this.$alertAction(this.dialog.alertActionType);
      this.dialog.alertText = ''
      this.dialog.alertOption= {
        persistent : false
      }
      this.dialog.alertActionType = ''
      eventBus.initAlert()
    },
    onReSize: function () {
      this.rightLayoutWidth = this.$refs.rightLayout.clientWidth
      this.leftLayoutWidth = this.$refs.leftLayout.clientWidth
    },
    setBalance: function (balance) {
      console.log('balance', balance)
      this.balance = balance
    },
    getSiteInfo: async function () {
      this.result = false
      let result = await this.$updateSiteInfo()
      this.result = result.flag
      if (result.flag) {
        if (result.data.underMaintenance) {
          this.dialog.underMaintenance = true
          console.log(result.data.constructionText)
          if (result.data.constructionText.length > 0) {
            this.dialog.underMaintenanceText = result.data.constructionText
          } else {
            this.dialog.underMaintenanceText = '사이트 점검 중이므로 이용하실 수 없습니다.';
          }
        }
      } else {
        if (result.status === 403) {
          this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent= true
        }
        this.dialog.alert = true;
        this.dialog.alertText = result.msg
      }

      console.log('localStorage', localStorage)
      console.log('localStorage', JSON.parse(localStorage.useCasino))
    },

  },
  mounted() {
    // this.getSiteInfo()
  },
  created() {
    eventBus.$on('getSiteInfo', async () => {
      await this.getSiteInfo()
    })
    eventBus.$on('getBalance', (balance) => {
      this.setBalance(balance)
    })
    eventBus.$on('showAlert', (text,option,action) => {
      this.showAlert(text,option,action)
    })


  },
  watch: {
    async $route(to, from) {
      await this.getSiteInfo()
      // this.currPath = to.path
      // console.log('currpath!!@!',this.currPath)
    },
  }
};
</script>


<style>


#app {
  font-family: 'Noto Sans KR', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px !important;
  max-width: 1920px !important;
  margin: auto;
}

.v-toolbar__content, .v-toolbar__extension {
  max-width: 1920px !important;
}

html {
  font-size: 14px !important;
}

.betSelected {
  background: #ffae00 !important;
  color: #000 !important;
}

.v-application ul, .v-application ol {
  padding-left: 0px;
}

</style>
