<template>

  <aside id="sidedrawer">
    <div id="sidedrawer_inner">
      <div class="nav-side-menu">
        <div class="sports-menu-list">
          <div data-toggle="collapse" data-target="#sports-sub" class="famous-title collapsed">
            <div class="famous-inner">
              <i class="mains-icon mains-sm mi-sports"></i> &nbsp;<span class="sports">스포츠 &nbsp;<span class="ENG_NAME">Sports</span></span>
              <div id="sports-chevron-icon" class="icon_collapsed"><i class="fa fa-chevron-down"></i></div>
            </div>
          </div>
          <div id="sports-menu-list-sub">
            <ul class="sub-menu collapse in" id="sports-sub">

              <div id="menu-prematch" style="">
                <div
                  v-for="(item,i) in sports"
                  :key="i"
                >
                  <li
                      data-toggle="collapse"
                      :data-target="'#sports_' + i"
                      class="collapsed"
                      v-if="item.count > 0"
                  >
                    <div class="item_sports_sports">
                      <i class="sports-icon"
                         :class="item.name === '축구' ? 'icon-football' :
                               item.name === '야구' ? 'icon-baseball' :
                               item.name === '농구' ? 'icon-basketball' :
                               item.name === '배구' ? 'icon-volleyball' :
                               item.name === '아이스하키' ? 'icon-hockey' :
                               item.name === '미식축구' ? 'icon-american-football' :
                               item.name === '종합격투기' ? 'icon-boxing' :
                               item.name === '이스포츠' ? 'icon-all' : ''">

                      </i>
                      <span class="sports">{{ item.name }}</span>
                      <span class="count-label-active">{{ item.count }}</span>
                    </div>
                  </li>
                  <ul class="sub-menu collapse out"
                      :id="'sports_' + i"
                  >
                    <template
                        v-for="(subItem, j) in item.children"
                    >
                      <li
                          :key="j"
                          data-toggle="collapse"
                          :data-target="'#sports_' + i + '_' + j"
                          class="sub-menu-prematch collapsed ">
                        <div class="item_sports_location">
                          <span class="arrow"></span>
                          <i class="flag-icon"
                             :class="'flag-icon-' + subItem.icon"
                          ></i>
                          <span class="flags">{{ subItem.name }}</span>
                          <span class="count-label">{{ subItem.count }}</span>
                        </div>
                      </li>
                      <div
                          :key="j+'_'"
                      >
                        <ul class="third-menu collapse out"
                            :id="'sports_' + i + '_' + j">
                          <li  class=""
                               v-for="(subSubItem, k) in subItem.children"
                               :key="k"
                          >
                            <div class="item_sports_league" @click="getGameByLeague(subSubItem.name)">
                              <i class="fa fa-bookmark"></i>
                              {{ subSubItem.name }} <span class="count-label-third">{{ subSubItem.count }}</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </template>
                  </ul>
                </div>
              </div>
            </ul>
          </div>

          <div id="menu-famous-wrap">
            <div data-toggle="collapse" data-target="#famous-sub" class="famous-title collapsed">
              <div class="famous-inner">
                <span class="sports"><i class="mains-icon mains-sm mi-favorite"></i> &nbsp;인기리그 &nbsp;<span class="ENG_NAME">Popular League</span></span>
                <div id="famous-chevron-icon" class="icon_collapsed"><i class="fa fa-chevron-down"></i></div>
              </div>
            </div>
            <div id="menu-famous-list">
              <ul class="sub-menu collapse in" id="famous-sub">
                <li v-for="(item,i) in popular"
                  :key="i"
                >
                  <div class="item_famous_sports" @click="getGameByLeague(item.league)">
                    <img :src="item.leagueIcon"> {{ item.league }} </div>
                </li>
              </ul>
            </div>
          </div>

          <div id="menu-add">
            <div data-toggle="collapse" data-target="#minigame-sub" class="famous-title collapsed">
              <div class="famous-inner">
                <span class="sports"><i class="mains-icon mains-sm mi-mini"></i> &nbsp;미니게임 &nbsp;<span class="ENG_NAME">Shipping Games</span></span>
                <div id="minigame-chevron-icon" class="icon_collapsed"><i class="fa fa-chevron-down"></i></div>
              </div>
            </div>
            <div id="menu-add-list">
              <ul class="sub-menu collapse in" id="minigame-sub">
                <li @click="goLink('POWERBALL')">
                  <div class="item_famous_mini">
                    <i class="fa fa-star"></i>
                    EOS파워볼(5분) </div>
                </li>
                <li @click="goLink('POWERBALL3M')">
                  <div class="item_famous_mini">
                    <i class="fa fa-star"></i>
                    EOS파워볼(3분) </div>
                </li>
<!--                <li @click="goLink('SPEEDKENO')">
                  <div class="item_famous_mini">
                    <i class="fa fa-star"></i>
                    EOS스피드키노(5분) </div>
                </li>-->
                <li @click="goLink('VSOCCER')">
                  <div class="item_famous_mini">
                    <i class="fa fa-star"></i>
                    BET365 가상게임 </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  </aside>

</template>

<script>
$(function() {
  /*
    if(!vb_mobile_device) {
      $('#menu-add-list').slimScroll({height:'230px'});
        $('#menu-famous').slimScroll({height:'30vh'});
    }
  */
  $('.collapse').on('show.bs.collapse', function () {
    if($(this).context.id == 'sports-sub') {
      $('#sports-chevron-icon').html('<i class="fa fa-chevron-down"></i>');

    } else if($(this).context.id == 'minigame-sub') {
      $('#minigame-chevron-icon').html('<i class="fa fa-chevron-down"></i>');

    } else if($(this).context.id == 'famous-sub') {
      $('#famous-chevron-icon').html('<i class="fa fa-chevron-down"></i>');
      /*
      if(!vb_mobile_device) {
          $('#menu-famous').slimScroll({height:'30vh'});
      }
      */
    }
  });

  $('.collapse').on('shown.bs.collapse', function () {
  });

  $('.collapse').on('hidden.bs.collapse', function () {
    if($(this).context.id == 'sports-sub') {
      $('#sports-chevron-icon').html('<i class="fa fa-chevron-up"></i>');

    } else if($(this).context.id == 'minigame-sub') {
      $('#minigame-chevron-icon').html('<i class="fa fa-chevron-up"></i>');

    } else if($(this).context.id == 'famous-sub') {
      $('#famous-chevron-icon').html('<i class="fa fa-chevron-up"></i>');
      /*
      setTimeout(function() {
        if(!vb_mobile_device) {
            $('#menu-famous').slimScroll({height:'0vh'});
        }
      }, 10);
      */
    }
  });

});
</script>

<script>

export default {
  name: "gameList",
  data: () => ({

  }),
  props: {
    sports: {
      type: Array
    },
    popular: {
      type: Array
    },
    arcade: {
      type: Array
    }
  },
  methods: {
    getGameByLeague: function (item) {
      this.$emit('getGameByLeague', item)
    },

    goLink: function (target) {
      this.$emit('goLink',target)
    },

  }
}
</script>



<style scoped>
.ps {
  height: 100%;
}

.gameSummary {
  background-color: #2a3245;
  margin: 1px;
  font-family: NanumGothic !important;
}

.gameSummaryTitle {
  background-color: #3a496a;
  margin: 1px;
  font-family: NanumGothic !important;

}

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 17px;
}

.v-application ul, .v-application ol {
  padding-left: 0px;
}

</style>