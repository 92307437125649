import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                // primary: '#FF7043',
                // secondary: '#b0bec5',
                // accent: '#455A64',
                // error: '#3E2723',
            },
        },
    },
});
