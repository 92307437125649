<template>
  <v-card
      class=""
  >
    <div>
      <v-slide-group
          show-arrows
      >
        <v-slide-item
            v-for="(item, i) in gameSummaryList"
            :key="i"
            v-slot="{ active, toggle }"
        >
          <v-btn
              class="mx-1 font-12"
              :input-value="active"
              active-class="purple white--text"
              tile
              large
              style="min-width: 70px; height: 48px"
              @click="getGameData(item.sportsName,'')"
          >
            <v-badge
                :content="item.gameCount.toString()"
                color="#3c3838"
                tile
                class="mt-2"

            >
              <v-img
                  :src="`/${item.sportsName}.png`"
                  class="shrink mr-2"
                  transition="scale-transition"
                  width="20"
                  height="20"
                  contain
              />
            </v-badge>
          </v-btn>
        </v-slide-item>
      </v-slide-group>
      <v-slide-group
          show-arrows
      >
        <v-slide-item
            v-for="(item, j) in popularLeagueList"
            :key="j"
            v-slot="{ active, toggle }"
        >
          <v-btn
              class="mx-1 mx-1 font-12"
              :input-value="active"
              active-class="purple white&#45;&#45;text"
              tile
              large
              style="min-width: 70px; height: 48px"
              @click="getGameData('',item.league)"
          >
            <v-badge
                :content="item.gameCount.toString()"
                color="#3c3838"
                tile
                v-if="item.gameCount>0"
            >
              <span style="font-family: GmarketSansMedium">{{ item.league }}</span>
            </v-badge>
          </v-btn>
        </v-slide-item>
      </v-slide-group>


<!--      탭버전-->
<!--      <v-tabs-->
<!--          show-arrows-->
<!--          optional-->
<!--      >-->
<!--        <v-tab-->
<!--            v-for="(item, i) in gameSummaryList"-->
<!--            :key="i"-->
<!--            @click="getGameData(item.sportsName,'')"-->
<!--        >-->
<!--          <v-badge-->
<!--              :content="item.gameCount.toString()"-->
<!--              color="#EF6C00"-->
<!--              rounded-->

<!--          >-->
<!--            <span style="font-family: GmarketSansMedium">{{ item.sportsName }}</span>-->
<!--          </v-badge>-->

<!--        </v-tab>-->
<!--      </v-tabs>-->
<!--      <v-tabs-->
<!--          show-arrows-->
<!--          optional-->
<!--      >-->
<!--        <v-tab-->
<!--            v-for="(item, j) in popularLeagueList"-->
<!--            :key="j"-->
<!--            @click="getGameData('',item.league)"-->
<!--        >-->
<!--          <v-badge-->
<!--              :content="item.gameCount.toString()"-->
<!--              color="#EF6C00"-->
<!--              rounded-->
<!--              v-if="item.gameCount>0"-->
<!--          >-->
<!--            <span style="font-family: GmarketSansMedium">{{ item.league }}</span>-->
<!--          </v-badge>-->

<!--        </v-tab>-->
<!--      </v-tabs>-->
    </div>

  </v-card>

</template>

<script>
export default {
  name: "gameList",
  data: () => ({}),
  props: {
    gameSummaryList: {
      type: Array
    },
    popularLeagueList: {
      type: Array
    }
  },

  methods: {
    getGameData: function (item, type) {
      this.$emit('getGameData', item, type)
    }
  }
}
</script>

<style scoped>
.ps {
  height: 100%;
}

.wn-menu-content-list .v-select-select {
  border: none;
}

.v-select-select option {
  padding: 4px;
  line-height: 24px;
  height: 24px;
  font-family: Tahoma;
  font-size: 1px;
  color: #003399;
}
</style>