<template>
  <div>
    <div
        v-if="$vuetify.breakpoint.lgAndUp"
    >
      <pc
          :cart="cart"
          :gameType="gameType"
          :balance="balance"
          :config="gameConfig"
          :width="width"
          v-on:deleteCart="deleteCart"
          v-on:deleteCartAll="deleteCartAll"
          v-on:setBetting="setBetting"
          ref="cart"
      >
      </pc>
    </div>
    <div
        v-else
        style="margin-top:100px"
    >
      <mobile
          :cart="cart"
          :gameType="gameType"
          :balance="balance"
          :config="gameConfig"
          :btnLoading="btnLoading"
          v-on:deleteCart="deleteCart"
          v-on:deleteCartAll="deleteCartAll"
          v-on:setBetting="setBetting"
          ref="cart"
      ></mobile>
    </div>
    <snack-bar
        :dialog.sync=dialog.snackBar
        :text=dialog.snackBarText
    >
    </snack-bar>
    <confirm-dialog
        :dialog.sync="dialog.confirm"
        :confirmData="dialog.confirmData"
        v-on:confirmLeftBtnAction="confirmLeftBtnAction"
        v-on:confirmRightBtnAction="confirmRightBtnAction">
    </confirm-dialog>

  </div>

</template>

<script>
import axios from "axios";

import Pc from "@/components/Game/Cart/Pc";
import Mobile from "@/components/Game/Cart/Mobile";
import {eventBus} from '@/plugins/eventBus';
export default {
  name: "cartIndex",
  components: {
    Pc,
    Mobile
  },
  data: () => ({
    btnLoading: false,
    isDisabled: false,
    gameType: 'SPORTS',
    cart: [],
    gameList: [],
    gameDetailList: {},
    dialog: {
      write: false,
      writeData: {},
      alert: false,
      alertText: '',
      alertActionType: '',
      snackBar: false,
      snackBarText: '',
      confirm: false,
      confirmData: {
        title: "",
        content: "",
        leftBtn: "",
        rightBtn: "",
        data: {}
      },
    },
    gameConfig: {},
  }),
  props: {
    balance: {
      type: Object
    },
    width: {
      type: Number
    }
  },
  methods: {
    checkBet: function (betInfo) {
      const configFlag = this.$checkConfig(this.gameConfig, betInfo)
      if (!configFlag.flag) {
        this.dialog.alertText = configFlag.msg;
        this.dialog.alert = true;
      }
    },
    refreshCartByBet: function (item) {
      console.log('refreshCart Start', item,this.cart)
      let index = -1;
      for (let i = 0; i < this.cart.length; i++) {
        for (let j = 0; j < item.length; j++) {
          if (item[j].gameId === this.cart[i].item.sportsGameId) {
            let oldOdds = item[j].oldOdds
            let newOdds = item[j].newOdds
            // indexOf 는 string만 취급
            let oddsHome = String(this.cart[i].item.oddsHome)
            let oddsDraw = String(this.cart[i].item.oddsDraw)
            let oddsAway = String(this.cart[i].item.oddsAway)
            if (item[j].whichBet === 'HOME') {
              index = oddsHome.indexOf(oldOdds)
            }
            if (item[j].whichBet === 'DRAW') {
              index = oddsDraw.indexOf(oldOdds)
            }
            if (item[j].whichBet === 'AWAY') {
              index = oddsAway.indexOf(oldOdds)
            }
            if (index !== -1) {
              // if (this.cart[i].item.selected === 0) {
              //   this.cart[i].item.oddsHome = Number(newOdds)
              // }
              // if (this.cart[i].item.selected === 1) {
              //   this.cart[i].item.oddsDraw = Number(newOdds)
              // }
              // if (this.cart[i].item.selected === 2) {
              //   this.cart[i].item.oddsAway = Number(newOdds)
              // }
              if (this.cart[i].target === 0) {
                this.cart[i].item.oddsHome = Number(newOdds)
              }
              if (this.cart[i].target === 1) {
                this.cart[i].item.oddsDraw = Number(newOdds)
              }
              if (this.cart[i].target === 2) {
                this.cart[i].item.oddsAway = Number(newOdds)
              }
              if (newOdds > oldOdds) {
                //this.$refs.cart.$refs['cartItem_' + item[j].sportsGameId][0].innerHTML = oldOdds + '<span class="mdi mdi-arrow-right"></span>' + '<span style="color:#F50057">' + newOdds + '</span>'
                this.$refs.cart.$refs['odds_' + item[j].sportsGameId][0].classList.add('mdi', 'mdi-arrow-up', 'oddsUp', 'oddChange')
              } else {
                //this.$refs.cart.$refs['cartItem_' + item[j].sportsGameId][0].innerHTML = oldOdds + '<span class="mdi mdi-arrow-right"></span>' + '<span style="color:#1565C0">' + newOdds + '</span>'
                this.$refs.cart.$refs['odds_' + item[j].sportsGameId][0].classList.add('mdi', 'mdi-arrow-down', 'oddsDown', 'oddChange')
              }
              this.$refs.cart.$refs['oldOdds_' + item[j].sportsGameId][0].innerText = `(${oldOdds})`
            }
          }
        }
      }
      if (this.cart.length > 0) {
        this.$refs.cart.reCalculation(this.cart);
      }
    },
    refreshBySocket:function(item){
      console.log('리프레시소켓',item)
      console.log('리프레시소켓-카트',this.cart)

      for (let i = 0; i < this.cart.length; i++) {
        if (this.cart[i].item.sportsGameId === item.sportsGameId) {
          if (this.cart[i].target === 0) {
            if (item.oddsHome > this.cart[i].item.oddsHome) {
              this.$refs.cart.$refs['odds_' + item.sportsGameId][0].classList.add('mdi', 'mdi-arrow-up', 'oddsUp', 'oddChange')
            } else if (item.oddsHome < this.cart[i].item.oddsHome) {
              this.$refs.cart.$refs['odds_' + item.sportsGameId][0].classList.add('mdi', 'mdi-arrow-down', 'oddsdown', 'oddChange')
            }
            this.cart[i].item.oddsHome = item.oddsHome
          }
          if (this.cart[i].target === 1) {
            if (item.oddsDraw > this.cart[i].item.oddsDraw) {
              this.$refs.cart.$refs['odds_' + item.sportsGameId][0].classList.add('mdi', 'mdi-arrow-up', 'oddsUp', 'oddChange')
            } else if (item.oddsDraw < this.cart[i].item.oddsDraw) {
              this.$refs.cart.$refs['odds_' + item.sportsGameId][0].classList.add('mdi', 'mdi-arrow-down', 'oddsdown', 'oddChange')
            }
            this.cart[i].item.oddsDraw = item.oddsDraw
          }
          if (this.cart[i].target === 2) {
            if (item.oddsAway > this.cart[i].item.oddsAway) {
              this.$refs.cart.$refs['odds_' + item.sportsGameId][0].classList.add('mdi', 'mdi-arrow-up', 'oddsUp', 'oddChange')
            } else if (item.oddsAway < this.cart[i].item.oddsAway) {
              this.$refs.cart.$refs['odds_' + item.sportsGameId][0].classList.add('mdi', 'mdi-arrow-down', 'oddsdown', 'oddChange')
            }
            this.cart[i].item.oddsAway = item.oddsAway
          }
        }
      }

      if (this.cart.length > 0) {
        this.$refs.cart.reCalculation(this.cart);
      }
    },

    initGameData: function (cart,gameList,gameDetailList,gameType) {
      this.cart = cart
      this.gameList = gameList
      this.gameDetailList = gameDetailList
      this.gameType = gameType
      console.log(cart,gameList,gameDetailList,gameType)
    },
    getConfig: function (config) {
      this.gameConfig = config
      console.log('config!!',config)
    },
    deleteCart: function (data, index) {
      if(this.gameType === 'SPORTS' || this.gameType == 'LIVE'){
        const deleteCartResult = this.$deleteCart(data, index,this.cart, this.gameList,this.gameDetailList,this.gameType)
        this.gameList = deleteCartResult.gameList
        this.gameDetailList = deleteCartResult.gameDetailList
        eventBus.initGameData(this.cart,this.gameList,this.gameDetailList,this.gameType)
        console.log(this.cart,this.gameList,this.gameDetailList)
        console.log(data, index,this.cart, this.gameList,this.gameDetailList)
      }

      else{
        this.cart = [];
      }

    },
    deleteCartAll: function () {
      this.cart = []
      for (let i in this.gameDetailList) {
        console.log(this.gameDetailList[i])
        for (let j = 0; j < this.gameDetailList[i].length; j++) {
          // this.$set(this.gameDetailList[i][j], "selected", "");
          this.gameDetailList[i][j].selected = ""
        }
      }
      for (let i = 0; i < this.gameList.length; i++) {
        // this.$set(this.gameList[i], "selected", "");
        this.gameList[i].selected = ""
      }
      eventBus.initGameData(this.cart,this.gameList,this.gameDetailList,this.gameType)
    },

    confirmRightBtnAction: function (data) {
      this.dialog.confirmData.leftBtn = ''
      this.dialog.confirmData.rightBtn = ''
      this.dialog.confirmData.title = ''
      this.dialog.confirmData.content = ''
      this.dialog.confirmData.data = {}
      this.dialog.confirm = false
    },
    confirmLeftBtnAction: async function (data) {

      console.log('게임 타입', this.gameType)
      console.log(this.gameConfig)
      const configFlag = this.$checkConfig(this.gameConfig, data.betInfo)
      console.log(configFlag)
      console.log(this.gameConfig)
      console.log('menuCode',this.$route.params.id)
      if (!configFlag.flag) {
        this.dialog.alertText = configFlag.msg;
        this.dialog.alert = true;
        this.confirmRightBtnAction()
      } else {
        this.btnLoading = true
        this.loading = true;
        let betInfos = [];

        let menuCode = ''
        let url = ''
        if (this.gameType === 'SPORTS') {
          menuCode = this.$route.params.id
          url = '/app/bet/sportsGame'
        } else if (this.gameType === 'virtual' || this.gameType === 'ETC' || this.gameType === 'vDogRacing') {
          menuCode = this.cart[0].item.menuCode
          url = `/app/bet/${this.cart[0].item.menuCode.toLowerCase()}`
        } else if (this.gameType === 'LIVE') {
          menuCode = 'LIVE'
          url = `/app/bet/liveGame`
        }
        for (let i = 0; i < this.cart.length; i++) {
          let betsValue = '';
          let oddsValue = '';
          if (this.gameType === 'SPORTS') {
            if (this.cart[i].target === 0) {
              betsValue = 'HOME'
              oddsValue = this.cart[i].item.oddsHome
            } else if (this.cart[i].target === 1) {
              betsValue = 'DRAW'
              oddsValue = this.cart[i].item.oddsDraw
            } else if (this.cart[i].target === 2) {
              betsValue = 'AWAY'
              oddsValue = this.cart[i].item.oddsAway
            }
          } else if (this.gameType === 'LIVE') {
            if (this.cart[i].target === 0) {
              betsValue = 'HOME'
              oddsValue = this.cart[i].item.oddsHome
            } else if (this.cart[i].target === 1) {
              betsValue = 'DRAW'
              oddsValue = this.cart[i].item.oddsDraw
            } else if (this.cart[i].target === 2) {
              betsValue = 'AWAY'
              oddsValue = this.cart[i].item.oddsAway
            }

          } else if (this.gameType === 'virtual' || this.gameType === 'vDogRacing') {
            if (this.gameType === 'vDogRacing') {
              if (this.cart[i].target === 0) {
                betsValue = 'DOG_1'
                oddsValue = this.cart[i].item.odds1
              } else if (this.cart[i].target === 1) {
                betsValue = 'DOG_2'
                oddsValue = this.cart[i].item.odds2
              } else if (this.cart[i].target === 2) {
                betsValue = 'DOG_3'
                oddsValue = this.cart[i].item.odds3
              } else if (this.cart[i].target === 3) {
                betsValue = 'DOG_4'
                oddsValue = this.cart[i].item.odds4
              } else if (this.cart[i].target === 4) {
                betsValue = 'DOG_5'
                oddsValue = this.cart[i].item.odds5
              } else {
                betsValue = 'DOG_6'
                oddsValue = this.cart[i].item.odds6
              }
            } else {
              if (this.cart[i].target === 0) {
                betsValue = 'HOME'
                oddsValue = this.cart[i].item.oddsHome
              } else if (this.cart[i].target === 1) {
                betsValue = 'DRAW'
                oddsValue = this.cart[i].item.oddsDraw
              } else if (this.cart[i].target === 2) {
                betsValue = 'AWAY'
                oddsValue = this.cart[i].item.oddsAway
              }
            }
          } else if (this.gameType === 'ETC') {
            if (this.cart[i].item.menuCode === 'SPEEDKENO') {
              if (this.cart[i].target === 'ODD_EVEN_H') {
                betsValue = 'ODD'
                oddsValue = this.cart[i].item.oddsHome
              } else if (this.cart[i].target === 'ODD_EVEN_A') {
                betsValue = 'EVEN'
                oddsValue = this.cart[i].item.oddsAway
              } else if (this.cart[i].target === 'OVER_UNDER_H') {
                betsValue = 'OVER'
                oddsValue = this.cart[i].item.oddsHome
              } else if (this.cart[i].target === 'OVER_UNDER_A') {
                betsValue = 'UNDER'
                oddsValue = this.cart[i].item.oddsAway
              } else if (this.cart[i].target === 'ODD_OVER_UNDER_H') {
                betsValue = 'ODD_OVER'
                oddsValue = this.cart[i].item.oddsHome
              } else if (this.cart[i].target === 'ODD_OVER_UNDER_A') {
                betsValue = 'ODD_UNDER'
                oddsValue = this.cart[i].item.oddsAway
              } else if (this.cart[i].target === 'EVEN_OVER_UNDER_H') {
                betsValue = 'EVEN_OVER'
                oddsValue = this.cart[i].item.oddsHome
              } else if (this.cart[i].target === 'EVEN_OVER_UNDER_A') {
                betsValue = 'EVEN_UNDER'
                oddsValue = this.cart[i].item.oddsAway
              }
            } else {
              if (this.cart[i].target === 'P_ODD_EVEN_H' || this.cart[i].target == 'P_SUM_ODD_EVEN_H') {
                betsValue = 'ODD'
                oddsValue = this.cart[i].item.oddsHome
              } else if (this.cart[i].target === 'P_ODD_EVEN_A' || this.cart[i].target == 'P_SUM_ODD_EVEN_A') {
                betsValue = 'EVEN'
                oddsValue = this.cart[i].item.oddsAway
              } else if (this.cart[i].target === 'P_OVER_UNDER_H' || this.cart[i].target == 'P_SUM_OVER_UNDER_H') {
                betsValue = 'OVER'
                oddsValue = this.cart[i].item.oddsHome
              } else if (this.cart[i].target === 'P_OVER_UNDER_A' || this.cart[i].target == 'P_SUM_OVER_UNDER_A') {
                betsValue = 'UNDER'
                oddsValue = this.cart[i].item.oddsAway
              } else if (this.cart[i].target === 0) {
                betsValue = 'MEDIUM'
                oddsValue = this.cart[i].item.oddsDraw
              } else if (this.cart[i].target === 'BALL_SIZE_H') {
                betsValue = 'SMALL'
                oddsValue = this.cart[i].item.oddsHome
              } else if (this.cart[i].target === 'BALL_SIZE_A') {
                betsValue = 'LARGE'
                oddsValue = this.cart[i].item.oddsAway
              }
            }
          }
          betInfos.push({
                gameCode: this.cart[i].item.gameCode,
                bet: betsValue,
                odds: oddsValue,
                gameId: this.cart[i].item.gameId
              }
          )
        }
        let betData = {
          menuCode: menuCode,
          betMoney: data.betInfo.betPrice,
          betInfos: betInfos,
        }
        console.log('betData', betData)
        let isSuccess = {flag: false, code: 400, msg: '', info: []};
        await axios
            .post(url,
                betData,{

                })
            .then(response => {
              console.log('response', response)
              if (response.status === 200) {
                // 사용가능
                isSuccess.flag = true;
                isSuccess.msg = response.data.message;
              } else if (response.status === 202) {
                isSuccess.info = response.data;
                isSuccess.flag = false;
                isSuccess.msg = "배당이 변동된 경기가 있습니다."
              } else {
                isSuccess.flag = false;
                isSuccess.msg = "베팅을 실패하였습니다.";
              }
              isSuccess.code = response.status;
            })
            .catch(ex => {
              console.warn("ERROR!!!!! : ", ex);
              isSuccess.flag = false;
              isSuccess.code = ex.response.status;
              isSuccess.msg = ex.response.data.message;

            });
        if (isSuccess.flag) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
          // this.dialog.snackBar = true;
          // this.dialog.snackBarText = isSuccess.msg;
          this.deleteCartAll();
          eventBus.updateTopBarData()
        } else {
          this.dialog.snackBar = false;
          this.dialog.snackBarText = '';
          if (isSuccess.code === 400) {
            this.dialog.alertText = isSuccess.msg;
            this.dialog.alert = true;
          } else if (isSuccess.code === 202) {
            this.dialog.alertText = isSuccess.msg;
            this.dialog.alert = true;
            this.refreshCartByBet(isSuccess.info.data);
          } else {
            this.dialog.alertText = '베팅을 실패하였습니다.';
            this.dialog.alert = true;
          }
        }
        this.confirmRightBtnAction()
        this.btnLoading = false
        this.loading = false;
      }
    },
    setBetting: async function (data) {
      this.dialog.confirmData.leftBtn = '확인'
      this.dialog.confirmData.rightBtn = '취소'
      this.dialog.confirmData.title = ''
      console.log(this.gameConfig.oneFolderBetPenalty)
      //1폴더제약
      if (this.cart.length === 1 && this.gameConfig.oneFolderBetPenalty > 0) {
        this.dialog.confirmData.content = `1폴더 베팅시 ${this.gameConfig.oneFolderBetPenalty} 의 페널티가 발생합니다.\n베팅하시겠습니까?`
      } else {
        this.dialog.confirmData.content = '베팅 하시겠습니까?'
      }

      this.dialog.confirmData.data.betInfo = data
      this.dialog.confirm = true

    },
    showAlert: function () {
      eventBus.showAlert(this.dialog.alertText,this.dialog.alertOption,this.dialog.alertActionType)
    },

    getData : function () {

    }

  },
  created() {
    this.$evnetBusGetDataDestroy()
    this.$eventBusGetData()
    eventBus.$on('initGameData', (cart,gameList,gameDetailList,gameType) => {

      this.initGameData(cart,gameList,gameDetailList,gameType)
    })
    eventBus.$on('getConfig', (config) => {
      console.log('eventbus config',config)
      this.getConfig(config)
    })

    eventBus.$on('refreshBySocket', (item) => {
      console.log('eventbus!!-refreshBySocket',item)
      this.refreshBySocket(item)
    })

    eventBus.$on('refreshCartByBet', (item) => {
      console.log('eventbus-refreshCartByBet!!',item)
      this.refreshCartByBet(item)
    })
    this.$eventBusInitAlert()
  },
  beforeRouteLeave(to, from, next) {
  },
  mounted() {
    this.getData()
    console.log('ref',this.$refs.cart)
  },
  watch: {
    $route(to, from) {
      this.cart = []
      this.gameList = []
      this.gameDetailList={}
    },

    dialog: {
      handler: function (val, oldVal) {
        console.log(this.dialog)
        if (this.dialog.alert) {
          this.showAlert()
        }
      },
      deep: true

    }

  }

}
</script>

<style scoped>

</style>