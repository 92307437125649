<template>
  <div class="basic-betting mt-3 px-3" id="cart-wrap" ref="cart-wrap"
       :style="{'width' : width + 'px'}"
  >
    <div>
      <div class="basic-betting-inner">
        <div class="basic-betting-title">
          <i class="mains-icon mains-sm mi-cart"></i> <span class="pull-left">베팅카트</span>
          <div class="checkbox pull-right">
            <i class="fa fa-thumb-tack"></i>
            <label style="font-size:1.5em;">
              <input type="checkbox" v-model="fixed" name="betting-cart-fixed" id="betting-cart-fixed" @click="cartFix()">
              <span class="cr"><i class="cr-icon fa fa-check"></i></span>
            </label>
          </div>
        </div>
        <div class="betting-category" style="display:none;">
          <div class="pull-left">
            <a id="gamecart_bet_style" class="btn_bet_style">
              멀티
            </a>
          </div>
        </div>
        <div class="betting-empty" id="gamecart_empty" style="display:none;">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          베팅을 하기위해 원하시는 <br>경기의 배당을 선택해주세요.
        </div>
        <div id="betting-wrap-rail">
          <div class="betting-wrap" id="gamecart_betlist">
            <div class="betting"
                 v-for="(item, i) in cart"
                 :key="i"
            >
              <div v-if="gameType === 'SPORTS' || gameType === 'LIVE'">
                <div>
                  <span class="team">{{ item.item.teamHome }} vs {{ item.item.teamAway }}</span>
                  <span class="wxl">
                    <div>
                      <img src="/img/betslip/bet-remove.png"
                           @click="deleteCart(item.item,item.index)"
                      >
                    </div>

                  </span>
                </div>
                <div><span class="outcome">{{ item.item.gameCodeText }}</span></div>
                <div>
                <span class="betname">
                  {{
                    item.target === 0 ? item.item.teamHome :
                        (item.target === 2 ? item.item.teamAway : '무승부')
                  }}
                  {{ item.item.gameCode !== 'MATCH' ? '(' + item.item.handicap + ')' : '' }}
                </span>
                  <span class="price">
                  <span :ref="'odds_' + item.item.sportsGameId">
                  {{
                      item.target === 0 ? item.item.oddsHome :
                          (item.target === 2 ? item.item.oddsAway : item.item.oddsDraw)
                    }}
                 </span>
                  <span :ref="'oldOdds_' + item.item.sportsGameId"></span>
                </span>
                  <p class="mb-0" :ref="'cartItem_' +  item.item.sportsGameId">
                  </p>
                  <span class="error-odds"></span>
                </div>
              </div>

              <div v-else-if="gameType === 'virtual'">
                <div>
                  <span class="team">{{ item.item.teamHome }} vs {{ item.item.teamAway }}</span>
                  <span class="wxl">
                    <div

                    >
                      <img src="/img/betslip/bet-remove.png"
                           @click="deleteCart(item.item)"
                      >
                    </div>

                  </span>
                </div>
                <div><span class="outcome">{{ item.item.gameCodeText }}</span></div>
                <div>
                <span class="betname">
                  {{
                    item.target === 0 ? item.item.teamHome :
                        (item.target === 2 ? item.item.teamAway : '무승부')
                  }}
                  {{ item.item.gameCodeText !== '승무패' ? '(' + item.item.handicap + ')' : '' }}
                </span>
                  <span class="price">
                  <span :ref="'odds_' + item.item.sportsGameId">
                  {{
                      item.target === 0 ? item.item.oddsHome :
                          (item.target === 2 ? item.item.oddsAway : item.item.oddsDraw)
                    }}
                 </span>
                  <span :ref="'oldOdds_' + item.item.sportsGameId"></span>
                </span>
                  <p class="mb-0" :ref="'cartItem_' +  item.item.sportsGameId">
                  </p>
                  <span class="error-odds"></span>
                </div>
              </div>

              <div v-else-if="gameType === 'vDogRacing'">
                <div>
                  <span class="team"></span>
                  <span class="wxl">
                    <div>
                      <img src="/img/betslip/bet-remove.png"
                           @click="deleteCart(item.item)"
                      >
                    </div>

                  </span>
                </div>
                <div><span class="outcome">{{ item.item.gameCodeText }}</span></div>
                <div>
                <span class="betname">
                  {{
                    item.target === 0 ? item.item.team1 :
                        (item.target === 1 ? item.item.team2 :
                            (item.target === 2 ? item.item.team3 :
                                item.target === 3 ? item.item.team4 :
                                    item.target === 4 ? item.item.team5 :
                                        item.target === 5 ? item.item.team6 : ''))
                  }}
                </span>
                  <span class="price">
                  <span :ref="'odds_' + item.item.sportsGameId">
                  {{
                      item.target === 0 ? item.item.odds1 :
                          (item.target === 1 ? item.item.odds2 :
                              (item.target === 2 ? item.item.odds3 :
                                  item.target === 3 ? item.item.odds4 :
                                      item.target === 4 ? item.item.odds5 :
                                          item.target === 5 ? item.item.odds6 : ''))
                    }}
                 </span>
                  <span :ref="'oldOdds_'"></span>
                </span>
                  <p class="mb-0" :ref="'cartItem_' ">
                  </p>
                  <span class="error-odds"></span>
                </div>
              </div>

              <div v-else>
                <div>
                  <span class="team">{{ item.item.menuCodeText + ' ' + item.item.round }} 회차</span>
                  <span class="wxl">
                    <div>
                      <img src="/img/betslip/bet-remove.png"
                           @click="deleteCart(item.item)"
                      >
                    </div>

                  </span>
                </div>
                <div><span class="outcome">{{ item.item.gameCodeText }}</span></div>
                <div>
                <span class="betname">
                  {{ printBetText.text }}
                </span>
                  <span class="price">
                  <span :ref="'odds_' + item.item.sportsGameId">
                  {{ printBetText.odds }}
                 </span>
                  <span :ref="'oldOdds_'"></span>
                </span>
                  <p class="mb-0" :ref="'cartItem_' ">
                  </p>
                  <span class="error-odds"></span>
                </div>
              </div>

            </div>
            <div class="betinput_single" id="betinput_single_7650193689808001" style="display:none;">
            </div>
          </div>
        </div>
        <div class="basic-betting-cart" id="gamecart_betinput">
          <table width="100%" border="0" cellpadding="0" cellspacing="0">
            <tbody>
            <tr>
              <td align="center" valign="middle" style="font-size:0.8em;font-weight:bold;padding:0px 0px;">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tbody>
                  <tr>
                    <td align="left">
                      베팅금액 &nbsp;<button type="button" id="btn_plus_1" class="btn-sub-grad3"
                                         @click="addBetPrice(0)"><i class="fa fa-repeat"></i></button>
                    </td>
                    <td width="60%" height="28px" align="right" bgcolor="000" class="text1_4"
                        style="border-radius:10px;">
                      <vuetify-money
                          label=""
                          placeholder=""
                          hide-details
                          dense
                          solo
                          height="20"
                          class="pa-0 ma-0 inputMoney input-text-right"
                          v-model="betPrice"
                          v-bind:clearable="clearable"
                          v-bind:readonly="false"
                          v-bind:outlined="true"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="options"
                          background-color="#1E1E1E"
                      ></vuetify-money>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="bg-sub-grad1">
          <span class="bg-sub-title pull-left">총 베팅수</span>
          <div class="pull-right">
            <span class="bg-sub-number">{{ betCount }}</span>
          </div>
        </div>
        <div id="id_win_dividend" class="bg-sub-grad1">
          <span class="bg-sub-title pull-left">총 배당률</span>
          <div class="pull-right">
            <span id="gamecart_divrate" class="bg-sub-number">{{ betOdds }}</span>
          </div>
        </div>
        <div id="id_win_prize" class="bg-sub-grad1">
          <span class="bg-sub-title pull-left">예상당첨금액</span>
          <div class="pull-right">
            <span id="gamecart_dividend" class="bg-sub-number">{{ betPrizeMoney | comma }}</span>
          </div>
        </div>
        <div class="bg-sub-buttons row">
          <div class="pr-1">
            <button type="button" class="btn-sub-grad" @click="addBetPrice(5000)">5,000</button>
          </div>
          <div>
            <button type="button" class="btn-sub-grad" @click="addBetPrice(30000)">30,000</button>
          </div>
        </div>
        <div class="bg-sub-buttons row">
          <div class="pr-1">
            <button type="button" class="btn-sub-grad" @click="addBetPrice(50000)">50,000</button>
          </div>
          <div>
            <button type="button" class="btn-sub-grad" @click="addBetPrice(100000)">100,000</button>
          </div>
        </div>
        <div class="bg-sub-buttons row">
          <div class="pr-1">
            <button type="button" class="btn-sub-grad" @click="addBetPrice(500000)">500,000</button>
          </div>
          <div>
            <button type="button" class="btn-sub-grad2" @click="addBetPrice('max')">MAX</button>
          </div>
        </div>
        <div class="btn-main-grad1" @click="setBetting">
          <span>베팅하기</span>
        </div>
        <div class="bg-sub-buttons row">
          <div class="pr-1">
            <button id="gamecart_clear" type="button" class="btn-third-grad2"
                    :disabled="cart.length <= 0"
                    @click="deleteCartAll">경기전체취소
            </button>
          </div>
          <div>
            <button type="button" class="btn-third-grad" onclick="goto_top();">맨위로 이동</button>
          </div>
        </div>
        <div v-if="Object.keys(config).length > 0">
          <div id="id_min_bet" class="bg-sub-grad2">
            <span class="pull-left">최소베팅금액</span>
            <div class="pull-right">
              <span id="gamecart_min_bet" class="bg-sub-number">{{ config.minBetAmt | comma }}</span>
            </div>
          </div>
          <div id="id_max_bet" class="bg-sub-grad2">
            <span class="pull-left">최대베팅금액</span>
            <div class="pull-right">
              <span id="gamecart_max_bet" class="bg-sub-number">{{ config.maxBetAmt | comma }}</span>
            </div>
          </div>
          <div id="id_max_prize" class="bg-sub-grad2">
            <span class="pull-left">최대당첨금액</span>
            <div class="pull-right">
              <span id="gamecart_max_prize" class="bg-sub-number">{{ config.maxWinAmt | comma }}</span>
            </div>
          </div>
        </div>

      </div>
      <!--
      <div class="side_banner">
        <img src="/img/info_regulations.png" @click="goLink('notice')">
      </div>
      <div class="side_banner">
        <a target="_blank"><img src="/img/info_domain.png"></a>
      </div>
      -->
    </div>

  </div>

</template>

<script>


export default {
  name: "BetCart",
  data() {
    return {
      fixed:true,
      calcCartHeight: 0,
      cardHeight: 100,
      betPrice: 5000,
      betCount: 0,
      betOdds: 1,
      betPrizeMoney: 0,
      scrollFlag: false,

      placeHolder: '',
      valueWhenIsEmpty: '0',
      clearable: false,
      options: {
        suffix: '',
        length: 8,
        precision: 0,
      },
    }
  },

  props: {
    cart: {
      type: Array
    },
    gameType: {
      type: String
    },

    width: {
      type: Number
    },
    balance: {
      type: Object
    },
    config: {
      type: Object
    }
  },
  methods: {
    goLink: function (target) {
      this.$goLink(target)
    },

    cartFix: function(){

      if(this.fixed == true){

        this.$refs["cart-wrap"].style.position = 'fixed'
        goto_top();
      }

      else {
        this.$refs["cart-wrap"].style.position = 'relative'
      }


    },

    addBetPrice: function (price) {
      console.log(this.config)
      this.betOdds = Number(Math.round(this.betOdds * 100) / 100)
      if (this.betPrice > this.balance.money) {
        this.betPrice = this.balance.money
      }
      if (price === 0) {
        this.betPrice = 0
      } else {
        if (this.cart.length === 1 && this.config.oneFolderMaxBetAmt !== null) {
          if (price === 'max') {
            if (this.balance.money > this.config.oneFolderMaxBetAmt) {
              if ((this.config.oneFolderMaxBetAmt * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.config.oneFolderMaxBetAmt
              }
            } else {
              if ((this.balance.money * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.balance.money
              }
            }
          } else {
            if (this.betPrice + price > this.config.oneFolderMaxBetAmt) {
              if ((this.config.oneFolderMaxBetAmt * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.config.oneFolderMaxBetAmt
              }
            } else {
              if (((this.betPrice + price) * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.betPrice + price
              }
            }
          }
        } else if (this.cart.length > 1) {
          if (price === 'max') {
            if (this.balance.money > this.config.maxBetAmt) {
              if ((this.config.maxBetAmt * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.config.maxBetAmt
              }
            } else {
              if ((this.balance.money * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.balance.money
              }
            }
          } else {
            if (this.betPrice + price > this.config.maxBetAmt) {
              if ((this.config.maxBetAmt * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.config.maxBetAmt
              }
            } else {
              if (((this.betPrice + price) * this.betOdds) > this.config.maxWinAmt) {
                this.betPrice = (this.config.maxWinAmt / this.betOdds)
              } else {
                this.betPrice = this.betPrice + price
              }
            }
          }
        } else {
          if (price === 'max') {
            if (this.balance.money > this.config.maxBetAmt) {
              this.betPrice = this.config.maxBetAmt
            } else {
              this.betPrice = this.balance.money
            }
          } else {
            if (this.balance.money > this.config.maxBetAmt) {
              if (this.betPrice + price > this.config.maxBetAmt) {
                this.betPrice = this.config.maxBetAmt
              } else {
                this.betPrice = this.betPrice + price
              }
            } else {
              if (this.betPrice + price > this.balance.money) {
                this.betPrice = this.balance.money
              } else {
                this.betPrice = this.betPrice + price
              }
            }
          }
        }
        this.betPrice = Math.floor(this.betPrice)
      }
    },

    deleteCart: function (item, index) {
      this.$emit('deleteCart', item, index)
    },
    deleteCartAll: function () {
      this.$emit('deleteCartAll')
    },
    onResize: async function () {
      // console.log('resize')
      await this.$nextTick()
      let cartHeight = this.$refs.cartCard.$el.clientHeight;
      let cartTitleHeight = this.$refs.cartCardTitle.clientHeight
      let carCardTotalHeight = this.$refs.cartCardTotal.$el.clientHeight
      let selectGameListHeight = this.$refs.selectGameList.$el.clientHeight

      //22는 여분
      if ((cartHeight - (cartTitleHeight + carCardTotalHeight + this.cardHeight + 20)) <= selectGameListHeight) {
        this.scrollFlag = true;
      } else {
        this.scrollFlag = false;
      }
      // 20은 여분
      this.calcCartHeight = cartTitleHeight + carCardTotalHeight + 22
    },
    setBetting: function () {

      let betInfo = {
        betPrice: this.betPrice,
        betCount: this.betCount,
        betOdds: this.betOdds,
        betPrizeMoney: this.betPrizeMoney
      }
      this.$emit('setBetting', betInfo)
    },

    reCalculation: function (cartList) {
      // this.onResize()
      this.betCount = cartList.length;
      this.betOdds = 1;
      if (this.gameType === 'vDogRacing') {
        console.log('cart', cartList)
        for (let i = 0; i < cartList.length; i++) {
          if (cartList[i].target === 0) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds1)
          } else if (cartList[i].target === 1) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds2)
          } else if (cartList[i].target === 2) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds3)
          } else if (cartList[i].target === 3) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds4)
          } else if (cartList[i].target === 4) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds5)
          } else {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds6)
          }
        }
      } else {
        for (let i = 0; i < cartList.length; i++) {
          if (cartList[i].target === 0) {
            this.betOdds = this.betOdds * Number(cartList[i].item.oddsHome)
          } else if (cartList[i].target === 1) {
            this.betOdds = this.betOdds * Number(cartList[i].item.oddsDraw)
          } else {
            this.betOdds = this.betOdds * Number(cartList[i].item.oddsAway)
          }

        }
      }
      this.betOdds = Number(Math.round(this.betOdds * 100) / 100)
      this.betPrizeMoney = Math.round(Number(this.betPrice) * this.betOdds)
      // this.betOdds = Number(Math.floor(this.betOdds))
      // this.betPrizeMoney = Math.floor(Number(this.betPrice) * this.betOdds)
    },

  },
  mounted() {
  },
  computed: {
    scrollerStyle() {
      return this.scrollFlag ? {
            // height: `${this.cartHeight}px`,
            height: `calc(100% - ${this.calcCartHeight}px)`,
          }
          : {}
    },
    printBetText() {
      let betText = '';
      let betOdds = '';
      if (this.cart[0].item.menuCode === 'SPEEDKENO') {
        if (this.cart[0].item.gameCode === 'ODD_EVEN') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '홀';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '짝';
            betOdds = this.cart[0].item.oddsAway
          }
        } else if (this.cart[0].item.gameCode === 'OVER_UNDER') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '오버';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '언더';
            betOdds = this.cart[0].item.oddsAway
          }
        } else if (this.cart[0].item.gameCode === 'ODD_OVER_UNDER') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '홀 + 오버';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '홀 + 언더';
            betOdds = this.cart[0].item.oddsAway
          }
        } else if (this.cart[0].item.gameCode === 'EVEN_OVER_UNDER') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '짝 + 오버';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '짝 + 언더';
            betOdds = this.cart[0].item.oddsAway
          }
        }
      } else {
        if (this.cart[0].item.gameCode === 'P_ODD_EVEN') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '파워볼 홀';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '파워볼 짝';
            betOdds = this.cart[0].item.oddsAway
          }
        } else if (this.cart[0].item.gameCode === 'P_OVER_UNDER') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '파워볼 오버';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '파워볼 언더';
            betOdds = this.cart[0].item.oddsAway
          }
        } else if (this.cart[0].item.gameCode === 'BALL_SIZE') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '일반볼 소';
            betOdds = this.cart[0].item.oddsHome
          } else if (this.cart[0].target === this.cart[0].item.gameCode + '_A') {
            betText = '일반볼 대';
            betOdds = this.cart[0].item.oddsAway
          } else {
            betText = '일반볼 중';
            betOdds = this.cart[0].item.oddsDraw
          }
        } else if (this.cart[0].item.gameCode === 'P_SUM_ODD_EVEN') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '일반볼 합 홀';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '일반볼 합 짝';
            betOdds = this.cart[0].item.oddsAway
          }
        } else if (this.cart[0].item.gameCode === 'P_SUM_OVER_UNDER') {
          if (this.cart[0].target === this.cart[0].item.gameCode + '_H') {
            betText = '일반볼 합 오버';
            betOdds = this.cart[0].item.oddsHome
          } else {
            betText = '일반볼 합 언더';
            betOdds = this.cart[0].item.oddsAway
          }
        }
      }


      return {
        text: betText,
        odds: betOdds
      }
    }

  },
  watch: {
    balance: {
      handler: function (val, oldVal) {
        this.options.length = this.balance.money.toString().length
      },
      deep: true
    },
    config: {
      handler: function (val, oldVal) {
        this.betPrice = this.config.commonMinBetAmt
      },
      deep: true
    },
    betPrice: function () {
      if (this.betPrice > this.balance.money) {
        this.betPrice = this.balance.money
      }
      // this.betPrizeMoney = Math.round(Number(this.betPrice) * this.betOdds)
      //최대 당첨 금액
      let tempBetOdds = Number(Math.round(this.betOdds * 100) / 100)
      let tempBetPrizeMoney = Math.floor(Number(this.betPrice) * this.betOdds)
      this.betOdds = tempBetOdds
      this.betPrizeMoney = tempBetPrizeMoney
    },

    cart: function (cartList) {
      // this.onResize()
      this.betCount = cartList.length;
      this.betOdds = 1;
      if (this.gameType === 'vDogRacing') {
        for (let i = 0; i < cartList.length; i++) {
          if (cartList[i].target === 0) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds1)
          } else if (cartList[i].target === 1) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds2)
          } else if (cartList[i].target === 2) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds3)
          } else if (cartList[i].target === 3) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds4)
          } else if (cartList[i].target === 4) {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds5)
          } else {
            this.betOdds = this.betOdds * Number(cartList[i].item.odds6)
          }
        }
      } else {
        for (let i = 0; i < cartList.length; i++) {
          if (cartList[i].target === 0) {
            this.betOdds = this.betOdds * Number(cartList[i].item.oddsHome)
          } else if (cartList[i].target === 1) {
            this.betOdds = this.betOdds * Number(cartList[i].item.oddsDraw)
          } else {
            this.betOdds = this.betOdds * Number(cartList[i].item.oddsAway)
          }
          this.betOdds = Number(Math.round(this.betOdds * 100) / 100)
          // this.betOdds = Number(Math.floor(this.betOdds))
        }

      }

      this.betPrice = Math.floor(this.betPrice)
      this.betOdds = Number(Math.round(this.betOdds * 100) / 100)
      // this.betPrizeMoney = Math.round(Number(this.betPrice) * this.betOdds)
      this.betPrizeMoney = Math.floor(Number(this.betPrice) * this.betOdds)
    }
  }

}
</script>



<style scoped>

.row {
  margin: 0px !important;
}

</style>
