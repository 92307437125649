<template>
  <div>
    <v-navigation-drawer v-model="drawer" app v-if="$vuetify.breakpoint.mdAndDown" class="overflow-y-auto">
      <div class="aside_mobile_menu_inner" style="width:100%">
        <div id="amenu_top">
          <a>
            <img src="/img/logo.png" alt="CORE SPORTS">
          </a>
        </div>
        <div class="amenu_main">
          <ul>
            <li
                v-for="(item,i) in menuItems"
                :key="i"
            >
              <a @click="checkMenuTab(item.path)" class="nav_1da normal">
                {{ item.title }} </a>
            </li>
          </ul>
        </div>
      </div>
    </v-navigation-drawer>
    <user-mobile
        v-if="$vuetify.breakpoint.mdAndDown"
        :nowTime="nowTime"
        :balance="balance"
        :unreadMessage="unreadMessage"
        :nickname="nickname"
        :gradeValue="gradeValue"
        :drawerRight.sync="drawerRight"
        v-on:toggleDrawerRight="toggleDrawerRight"
        v-on:logout="logout"
        v-on:goLink="goLink"
        v-on:checkMenuTab="checkMenuTab"
        v-on:autoAsk="autoAsk"
    >
    </user-mobile>
    <v-app-bar
        app
        color=""
        dark
        dense
        id="appBar"
        elevation="0"
        :height="$vuetify.breakpoint.lgAndUp ? '100' : '60'"
    >

      <v-container
          :fluid="$route.matched[0].props.appBarFullWidth"
          class="py-0"
          :class="$vuetify.breakpoint.lgAndUp ? '': 'px-0'"
      >
        <div class="d-flex align-center">
          <div class="d-flex align-center">
            <span class="hidden-lg-and-up">
              <v-app-bar-nav-icon color="#d401ec" @click="drawer = !drawer"></v-app-bar-nav-icon>
            </span>

            <v-img
                alt="Vuetify Logo"
                class="shrink mr-2 hidden-md-and-up"
                contain
                src="/img/logo.png"
                transition="scale-transition"
                :width="$vuetify.breakpoint.lgAndUp ? 150: 48"
                :height="$vuetify.breakpoint.lgAndUp ? 160: 48"
                @click="goLink('main')"

            />
          </div>
          <v-spacer></v-spacer>

          <user-pc
              v-if="$vuetify.breakpoint.lgAndUp"
              :nowTime="nowTime"
              :balance="balance"
              :unreadMessage="unreadMessage"
              :nickname="nickname"
              :gradeValue="gradeValue"
              :menuItems="menuItems"
              v-on:logout="logout"
              v-on:goLink="goLink"
              v-on:checkMenuTab="checkMenuTab"
              v-on:autoAsk="autoAsk"

          ></user-pc>
          <div v-else>
            <v-app-bar-nav-icon color="#d401ec" @click="drawerRight = !drawerRight">
              <v-icon>mdi-account</v-icon>
            </v-app-bar-nav-icon>
          </div>


        </div>
      </v-container>

    </v-app-bar>

    <confirm-dialog
        :dialog.sync="dialog.confirm"
        :confirmData="dialog.confirmData"
        v-on:confirmLeftBtnAction="confirmLeftBtnAction"
        v-on:confirmRightBtnAction="confirmRightBtnAction">
    </confirm-dialog>
  </div>
</template>

<script>
import axios from "axios";
import userPc from "@/components/_Default/User/Pc"
import userMobile from "@/components/_Default/User/Mobile"
import {eventBus} from '@/plugins/eventBus';
import Stomp from "webstomp-client";
import SockJS from "sockjs-client";

export default {
  name: "TopBar",

  data: () => ({
    interval: null,
    drawer: false,
    drawerRight: false,
    nowTime: 0,
    balance: {},
    unreadMessage: 0,
    nickname: '',
    gradeValue: '',
    menuItems: [
      /*{title: '라이브', path: '/live'},*/
      {title: '크로스', path: '/game/sports/CROSS'},
      {title: '스페셜', path: '/game/sports/SPECIAL'},
      {title: '라이브', path: '/game/LIVE'},
      {title: '카지노', path: '/casino'},
      {title: '가상게임', path: '/game/virtual'},
      {title: '미니게임', path: '/game/powerball'},
      /*
            {title: '경기결과', path: '/result'},
            {title: '베팅내역', path: '/mybet'},
      {title: '게시판', path: '/board'},
      */

      {title: '공지사항', path: '/notice/NOTICE'},
      {title: '이벤트', path: '/notice/EVENT'},
      /*{title: '충전', path: '/deposit'},
      {title: '환전', path: '/withdrawal'},
      {title: '포인트', path: '/point'},
      {title: '고객센터', path: '/customer'},
*/
    ],
    dialog: {
      alert: false,
      alertOption: {
        persistent: true
      },
      alertText: '',
      alertActionType: '',
      snackBar: false,
      snackBarText: '',
      confirm: false,
      confirmData: {
        title: "",
        content: "",
        leftBtn: "",
        rightBtn: "",
        data: {}
      },
    },
    menuTab: null,
    currPath: '',
    connected: false,
  }),
  components: {
    userPc,
    userMobile
  },
  methods: {
    toggleDrawerRight: function () {
      this.drawerRight = !this.drawerRight
    },
    setMenuTab: function () {
      this.currPath = this.$route.path
      const tabIndex = this.menuItems.findIndex(tab => tab.path === this.$route.path)
      this.menuTab = tabIndex

    },
    checkMenuTab: async function (targetPath) {
      console.log(targetPath, this.currPath)
      if (targetPath === this.currPath) {
        await eventBus.getSiteInfo()
        eventBus.getData()
      } else {
        this.$router.push({path: targetPath})
      }
    },
    goLink: function (target) {
      this.$goLink(target)
    },
    showAlert: function () {
      eventBus.showAlert(this.dialog.alertText, this.dialog.alertOption, this.dialog.alertActionType)
    },

    logout: function () {
      this.dialog.confirmData.leftBtn = '확인'
      this.dialog.confirmData.rightBtn = '취소'
      this.dialog.confirmData.title = '로그아웃'
      this.dialog.confirmData.content = '로그아웃 하시겠습니까?'
      this.dialog.confirm = true
    },
    confirmRightBtnAction: function () {
      this.dialog.confirmData.leftBtn = ''
      this.dialog.confirmData.rightBtn = ''
      this.dialog.confirmData.title = ''
      this.dialog.confirmData.content = ''
      this.dialog.confirm = false
    },
    confirmLeftBtnAction: async function () {
      //로그아웃 api 추가
      axios
          .post("/app/auth/signout", {})
          .then(response => {
            console.log('refresh !!! STORE', response)

            // 성공적으로 로그인이 되었을 경우
            if (response.status === 200) {

              console.log('signout success')

            } else {
              console.log('signout fails')
            }

          }).catch(async ex => {
        console.log("signout fail", ex)
      });

      this.$logout()
    },

    setDate() {
      let weekday = ['일', '월', '화', '수', '목', '금', '토']
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1;
      let date = new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate();
      let week = weekday[new Date().getDay()];
      let hh = new Date().getHours() < 10 ? "0" + new Date().getHours() : new Date().getHours();
      let mm = new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds();
      return {
        'year': year
        , 'month': month
        , 'date': date
        , 'week': week
        , 'hh': hh
        , 'mm': mm
        , 'ss': ss
      }
    },
    nowTimes() {
      this.nowTime =
          // this.setDate().year + "-" + this.setDate().month + "-" + this.setDate().date + "-" +
          this.setDate().year + "-" + this.setDate().month + "-" + this.setDate().date + " (" + this.setDate().week + ") " + "<br />" + this.setDate().hh + ":" + this.setDate().mm + ":" + this.setDate().ss;
      // this.setDate().hh + ":" + this.setDate().mm + ":" + this.setDate().ss;
      eventBus.getNowTime(this.nowTime.replace('<br />', ''))
    },

    getMember: async function () {
      console.log('getMember')
      if (this.$route.path !== '/login' && this.$route.path !== '/join') {
        await this.$updateMyInfo()
      }
      this.nickname = localStorage.nickname
      this.gradeValue = localStorage.gradeValue
    },
    getBalance: function () {
      axios
          .get('/app/member/balance',
              {
                params: {},
              })
          .then(response => {
            // console.log(response)
            this.balance = response.data
          })
          .catch(async ex => {
            if (ex.response.status === 401) {
              let refreshTokenResult = await this.$refreshToken()
              if (refreshTokenResult.flag) {
                await this.getBalance()
              } else {
                if (refreshTokenResult.status === 403) {
                  this.dialog.alertActionType = 'logout'
                  this.dialog.alertOption.persistent = true
                }
                this.dialog.alert = true;
                this.dialog.alertText = refreshTokenResult.msg

              }
            } else if (ex.response.status === 403) {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }

          })

    },
    getUnreadMessage: async function () {
      await axios
          .get('/app/messages/unread',
              {
                params: {},
              })
          .then(response => {
            // console.log(response.data)
            this.unreadMessage = response.data
          })
          .catch(async ex => {
            if (ex.response.status === 401) {
              let refreshTokenResult = await this.$refreshToken()
              if (refreshTokenResult.flag) {
                await this.getUnreadMessage()
              } else {
                if (refreshTokenResult.status === 403) {
                  this.dialog.alertActionType = 'logout'
                  this.dialog.alertOption.persistent = true
                }
                this.dialog.alert = true;
                this.dialog.alertText = refreshTokenResult.msg

              }
            } else if (ex.response.status === 403) {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }
          })
      if (this.unreadMessage > 0 && !this.$route.path.includes('/message')) {
        this.checkUnreadMessage();
      }
    },
    checkUnreadMessage: function () {
      this.dialog.alertText = '읽지 않은 매세지가 있습니다. 확인하시겠습니까?';
      this.dialog.alert = true;
      this.dialog.alertActionType = 'goMessage'
    },


    getData: function () {
      this.getMember()
      this.getBalance()
      this.getUnreadMessage()
      this.setMenuTab()
    },
    memberStatusCheck: function () {
      // console.log(localStorage.memberId)
      // console.log(localStorage.username)
      // console.log(localStorage.nickname)
      // console.log(localStorage.grade)
      // console.log(localStorage.gradeValue)
      // console.log(localStorage.status)
      // console.log(localStorage.statusValue)
      const status = localStorage.status;
      if (status === 'AUTHORIZED') {

      } else {
        this.dialog.alertText = '접속 불가한 계정입니다. 관리자에게 문의하시길 바랍니다.';
        this.dialog.alert = true;
        this.dialog.alertActionType = 'doNotAccess'
        // if (localStorage.status === 'UNAUTHORIZED') {
        //
        // } else if (localStorage.status === 'PAUSE') {
        //
        // } else if (localStorage.status === 'WITHDRAW') {
        //
        // }
      }
    },

    showAlarm: function (item) {
      this.dialog.alert = true;
      this.dialog.alertText = item.message;
      this.dialog.alertActionType = '';
    },

    autoAsk: async function () {
      this.loading = true;
      let isSuccess = {flag: false, code: 400, msg: ''};
      await axios
          .post('/app/store/charge/autoAsk',
              {
                headers: {"Content-Type": `application/json`}
              })
          .then(response => {
            if (response.status === 200) {
              // 사용가능
              isSuccess.flag = true;
              isSuccess.msg = response.data.message;
            } else {
              isSuccess.flag = false;
              isSuccess.msg = "1:1 문의를 실패하였습니다.";
            }
            isSuccess.code = response.status;
          })
          .catch(ex => {
            console.warn("ERROR!!!!! : ", ex);
            isSuccess.flag = false;
            isSuccess.code = ex.response.status;
            isSuccess.msg = ex.response.data.message;
          })

      if (isSuccess.flag) {
        this.dialog.alertText = isSuccess.msg;
        this.dialog.alert = true;
        this.dialog.alertActionType = 'goCustomer';
        // this.dialog.write = false;
        // this.dialog.snackBar = true;
        // this.dialog.snackBarText = isSuccess.msg;
      } else {

        this.dialog.snackBar = false;
        this.dialog.snackBarText = '';
        if (isSuccess.code === 400) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
        } else if (isSuccess.code === 401) {
          let refreshTokenResult = await this.$refreshToken()
          if (refreshTokenResult.flag) {
            await this.autoAsk()
          } else {
            if (refreshTokenResult.status === 403) {
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            }
            this.dialog.alert = true;
            this.dialog.alertText = refreshTokenResult.msg
          }

        } else if (isSuccess.code === 403) {
          this.dialog.alert = true;
          this.dialog.alertText = isSuccess.msg
          this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent = true
        } else {
          this.dialog.alertText = '1:1 문의를 실패하였습니다.';
          this.dialog.alert = true;
        }
      }
      this.loading = false;
    },

    connect() {
      this.$initSocket('USER')
      this.$stompUser.connect(
          {},
          frame => {
            this.connected = true;
            this.$stompUser.subscribe(`/queue/${localStorage.memberId}/alert`, async tick => {
              console.log('topbar!')
              this.showAlarm(JSON.parse(tick.body))
            });
          },
          error => {
            console.log(error);
            this.connected = false;
          }
      );
    },
    disconnect() {
      this.$socketDisconnect('USER')
      this.connected = false;
    },
    tickleConnection() {
      this.connected ? this.disconnect() : this.connect();
    },
  },
  mounted() {
    console.log("topbar!!!!")
    this.getData();
    this.connect();
  },
  created() {
    eventBus.$on('updateTopBarData', () => {
      this.getData()
    })
    this.nowTimes();
    this.setDate()

    setInterval(this.nowTimes.bind(this), 1000);
    //회원 상태 체크
    setInterval(this.memberStatusCheck.bind(this), 3000);
    //회원정보 불러오기
    // setInterval(this.getMember.bind(this), 5000);
  },

  watch: {
    $route(to, from) {
      // console.log(to,from)
      this.getData()
      this.currPath = to.path
      // console.log('currpath!!@!',this.currPath)
    },
    dialog: {
      handler: function (val, oldVal) {
        if (this.dialog.alert) {
          this.showAlert()
        }
      },
      deep: true

    }
  }
}
</script>

<style scoped>
/*ul {*/
/*  list-style: none;*/
/*}*/
/*ul li {*/
/*  float:left;*/
/*  font-size: 24px;*/
/*}*/
/*.menuTag {*/
/*  text-decoration: none;*/
/*  color: white;*/
/*}*/
/deep/ #appBar .v-toolbar__extension {
  justify-content: center;
  background-color: #1e1e1e;
  border-bottom: 1px solid #FFAE00 !important;
}

.v-tab {

  /*font-size: 0.905 rem;*/
  /*font-family: NanumGothic !important;*/
  font-weight: 600;
  /*color:#c3c3c3 !important;*/
}

.menuTab {
  border-bottom: 1px solid #FFAE00 !important;
}

.selectedMenu, .v-tab--active {
  /*background-color: #2196f3*/
  background-color: #FFAE00 !important;
  color: #000 !important;
  /*background: radial-gradient(#762006bf, transparent);*/
  font-weight: 0 !important;
}

p {
  margin-right: 5px
}

nav {
  height: calc(var(--vh, 1vh) * 100) !important;
}


</style>
