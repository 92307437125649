<template>
<!--    <v-btn-->
<!--        class="ml-2"-->
<!--        text-->
<!--        x-small-->
<!--        @click="drawerRight = !drawerRight"-->
<!--        color="#d401ec"-->
<!--    >-->
<!--      <v-icon>mdi-account</v-icon>-->
<!--    </v-btn>-->
<!--    <v-app-bar-nav-icon color="#d401ec" @click="drawerRight = !drawerRight"> <v-icon>mdi-account</v-icon></v-app-bar-nav-icon>-->
    <v-navigation-drawer right
                         v-model="drawer"
                         app
                         class="overflow-y-auto">
      <status
          :width="0"
      />
<!--      <div class="aside_mobile_user_inner" style="width:100%">-->
<!--        <section id="ol_after" class="ol">-->
<!--          <header id="ol_after_hd">-->
<!--            <div class="ol_m_info">-->
<!--              <div class="myinfo" onclick="goto_page('/help/mypage');">-->
<!--                <strong>LV. 3</strong>&nbsp;&nbsp;구구고고 님-->
<!--              </div>-->
<!--              <a href="https://cr-496.com/help/logout" id="ol_after_logout" class="logout pull-right">-->
<!--                로그아웃-->
<!--              </a>-->
<!--            </div>-->
<!--          </header>-->
<!--          <div id="ol_after_detail">-->
<!--            <div class="ol_m_detail">-->
<!--              <div>보유머니</div>-->
<!--              <span class="pull-right">&nbsp;원</span>-->
<!--              <span id="id_my_money" class="pull-right">-->
<!--              56 </span>-->
<!--            </div>-->
<!--            <div class="ol_m_detail">-->
<!--              <div>포인트</div>-->
<!--              <a href="/help/expoint">전환</a>-->
<!--              <span class="pull-right">&nbsp;P</span>-->
<!--              <span id="id_my_point" class="pull-right">-->
<!--              6,913 </span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <ul id="ol_after_private">-->
<!--            <li><a href="https://cr-496.com/help/mypage">마이페이지</a></li>-->
<!--            <li>-->
<!--              <a href="https://cr-496.com/help/memo" class="btn_purple">쪽지함</a>-->
<!--              <span class="arm_on">0</span>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </section>-->
<!--        <div class="amenu_main">-->
<!--          <ul>-->
<!--            <li><a href="/help/qalist" class="nav_1da off"><i class="mains-icon mi-query" style="position:absolute;top:13px;left:15px;"></i>1:1 문의</a></li>-->
<!--            <li><a href="https://cr-496.com/plugin/attendance/attendance.php" class="nav_1da off"><i class="mains-icon mi-attendance" style="position:absolute;top:13px;left:15px;"></i>출석부</a></li>-->
<!--            <li><a href="https://cr-496.com/result/sports" class="nav_1da off"><i class="mains-icon mi-mypage" style="position:absolute;top:13px;left:15px;"></i>경기결과</a></li>-->
<!--            <li><a href="/sports/history" class="nav_1da off"><i class="mains-icon mi-history" style="position:absolute;top:13px;left:15px;"></i>베팅내역</a></li>-->
<!--            <li><a href="/banking?sca=out" class="nav_1da off"><i class="mains-icon mi-withdraw-side" style="position:absolute;top:13px;left:15px;"></i>환전신청</a></li>-->
<!--            <li><a href="/banking" class="nav_1da off"><i class="mains-icon mi-deposit-side" style="position:absolute;top:13px;left:15px;"></i>충전신청</a></li>-->
<!--            <li><a href="/help/qawrite?w=b" class="nav_1da off"><i class="mains-icon mi-account-side" style="position:absolute;top:13px;left:15px;"></i>계좌문의</a></li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        <div style="height:100px;"></div>-->
<!--      </div>-->
    </v-navigation-drawer>
</template>

<script>
// Mixins
// import Proxyable from 'vuetify/lib/mixins/proxyable'
// Vuex
import Status from "@/components/_Default/User/Status";

export default {
  name: 'DashboardCoreSettings',
  data: () => ({
    menu: false,
    calcCartHeight: 0,
    drawer: false
  }),
  props: {
    drawerRight: {
      type: Boolean
    },
    nowTime: {
      type: String
    },
    balance: {
      type: Object
    },
    unreadMessage: {
      type: Number
    },
    nickname: {
      type: String
    },
    gradeValue: {
      type: String
    }
  },
  components: {
    Status
  },
  methods: {
    toggleDrawerRight: function () {
      console.log('aaa')
      this.$emit("toggleDrawerRight")
    },
    logout: function () {
      this.$emit('logout');
    },
    openStatusBoard: async function () {
      await this.$nextTick();
      console.log('window', window.innerHeight)
      setTimeout(() => {
        console.log(this.$refs.statusBoard)
        console.log(this.$refs.statusBoard.$el.clientHeight)
        console.log(this.$refs.statusBoard.$el.scrollHeight)
        console.log(window.innerHeight)
      }, 400)

    },
    goLink: function (target) {
      this.$emit('goLink',target);
    },

  },
  mounted() {
  },
  computed: {

  },
  watch: {
    drawerRight: function () {
      this.drawer = this.drawerRight
    },
    drawer: function () {
      this.$emit('input', this.drawer)

    }
  }
}
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 3px
    border-style: solid
    border-color: transparent !important

    &--active
      border-color: #00cae3 !important
</style>
